// extracted by mini-css-extract-plugin
export var category = "PageDataBodyAllNewsPosts-module--category--otuCV";
export var dropdown = "PageDataBodyAllNewsPosts-module--dropdown--1vqoc";
export var hidden = "PageDataBodyAllNewsPosts-module--hidden--a9VeU";
export var loadMore = "PageDataBodyAllNewsPosts-module--loadMore--CsTuz";
export var loadMoreButton = "PageDataBodyAllNewsPosts-module--loadMoreButton--KM4FS";
export var postButton = "PageDataBodyAllNewsPosts-module--postButton--qF-nQ";
export var postCard = "PageDataBodyAllNewsPosts-module--postCard--CZgJB";
export var postCopy = "PageDataBodyAllNewsPosts-module--postCopy--VlkUR";
export var postImage = "PageDataBodyAllNewsPosts-module--postImage--ybJhr";
export var postInfo = "PageDataBodyAllNewsPosts-module--postInfo--okDgZ";
export var postTitle = "PageDataBodyAllNewsPosts-module--postTitle--33bmc";
export var posts = "PageDataBodyAllNewsPosts-module--posts--3aRkt";
export var published = "PageDataBodyAllNewsPosts-module--published--hSgTY";
export var summary = "PageDataBodyAllNewsPosts-module--summary--rs0Pa";
export var tags = "PageDataBodyAllNewsPosts-module--tags--CdIxp";