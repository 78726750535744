import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import { useCallback, useEffect, useState } from "react"
import { isFilled } from "@prismicio/helpers"
import { RichText } from "../components/RichText"
import { PrevButton } from "../components/PrevButton"
import { NextButton } from "../components/NextButton"
import clsx from "clsx"
import useEmblaCarousel from "embla-carousel-react"

import type { PageDataBodyInlineGalleryFragment } from "../graphql.gen"

import * as emblaStyles from "../styles/EmblaCarousel.module.css"
import * as styles from "./PageDataBodyInlineGallery.module.css"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyInlineGallery = ({ slides }: Props) => {
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState(0)

	const [emblaRef, embla] = useEmblaCarousel({
		align: "start",
		slidesToScroll: 1,
	})

	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

	const onSelect = useCallback(() => {
		if (!embla) return
		setSelectedIndex(embla.selectedScrollSnap())
		setPrevBtnEnabled(embla.canScrollPrev())
		setNextBtnEnabled(embla.canScrollNext())
	}, [embla])

	useEffect(() => {
		if (!embla) return
		onSelect()
		embla.on("select", onSelect)
		embla.reInit()
	}, [embla, onSelect])

	return (
		<section className={styles.gallery}>
			<div
				className={clsx(emblaStyles.embla, styles.gallerySlides)}
				ref={emblaRef}
			>
				<div
					className={clsx(emblaStyles.emblaContainer, styles.slidesContainer)}
				>
					{slides.map((item, index) => (
						<div
							key={index}
							className={clsx(emblaStyles.emblaSlide, styles.gallerySlide)}
						>
							<div className={styles.galleryImage}>
								{item.image && (
									<GatsbyImage image={item.image} alt={item.imageAlt || ""} />
								)}
							</div>

							<div
								className={clsx(
									styles.caption,
									index === selectedIndex && styles.captionActive,
								)}
							>
								{isFilled.richText(item.caption) && (
									<RichText field={item.caption} />
								)}
							</div>
						</div>
					))}
				</div>
			</div>

			<PrevButton
				enabled={prevBtnEnabled}
				onClick={scrollPrev}
				className={styles.prev}
			/>
			<NextButton
				enabled={nextBtnEnabled}
				onClick={scrollNext}
				className={styles.next}
			/>
		</section>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyInlineGalleryFragment>) {
	return {
		slides: data.items.map((item) => {
			return {
				image: item.image?.gatsbyImageData as IGatsbyImageData,
				imageAlt: item.image?.alt,
				caption: item.caption?.richText,
			}
		}),
	}
}

export const fragment = graphql`
	fragment PageDataBodyInlineGallery on PrismicPageDataBodyInlineGallery {
		items {
			image {
				gatsbyImageData(width: 800)
				alt
			}
			caption {
				richText
			}
		}
	}

	fragment NewsPostDataBodyInlineGallery on PrismicNewsPostDataBodyInlineGallery {
		items {
			image {
				gatsbyImageData(width: 800, layout: CONSTRAINED)
				alt
			}
			caption {
				richText
			}
		}
	}

	fragment StoryDataBodyInlineGallery on PrismicStoryDataBodyInlineGallery {
		items {
			image {
				gatsbyImageData(width: 800, layout: CONSTRAINED)
				alt
			}
			caption {
				richText
			}
		}
	}
`

export default PageDataBodyInlineGallery
