import { graphql } from "gatsby"
import { type IGatsbyImageData } from "gatsby-plugin-image"

import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"

import type { PageDataBodyCardGridFragment } from "../graphql.gen"
import { PageDataBodyStoryCards } from "./PageDataBodyStoryCards"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyCardGrid = ({ heading, copy, cards }: Props) => {
	return (
		<PageDataBodyStoryCards
			cards={cards}
			footnote={copy}
			heading={heading}
			withWheel={false}
			buttonOneLink={undefined}
			buttonOneText={undefined}
			buttonTwoLink={undefined}
			buttonTwoText={undefined}
			copy={undefined}
		/>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyCardGridFragment>) {
	return {
		heading: data.primary.heading?.text,
		copy: data.primary.copy?.richText,
		cards: data.items.map((item) => ({
			image: item.image?.gatsbyImageData as IGatsbyImageData,
			imageAlt: item.image?.alt,
			heading: item.heading?.text,
			subheading: item.subheading?.text,
			href: item.link?.url,
		})),
	}
}

export const fragment = graphql`
	fragment PageDataBodyCardGrid on PrismicPageDataBodyCardGrid {
		primary {
			heading {
				text
			}
			copy {
				richText
			}
		}

		items {
			image {
				alt
				gatsbyImageData(
					width: 700
					layout: FULL_WIDTH
					breakpoints: [234, 500, 700]
				)
			}
			heading {
				text
			}
			subheading {
				text
			}
			link {
				url
				target
			}
		}
	}
`

export default PageDataBodyCardGrid
