import { graphql } from "gatsby"
import clsx from "clsx"
import { isFilled } from "@prismicio/helpers"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"

import { type MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { RichText } from "../components/RichText"
import { ContentContainer } from "../components/ContentContainer"
import { LinkWithArrow } from "../components/LinkWithArrow"

import type { PageDataBodyInteriorPromoFragment } from "../graphql.gen"

import * as typography from "../styles/typography.module.css"
import * as aspectRatio from "../styles/aspect-ratio.module.css"
import * as styles from "./PageDataBodyInteriorPromo.module.css"
import { SliceContainer } from "../components/SliceContainer"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyInteriorPromo = ({
	image,
	imageAlt,
	heading,
	subheading,
	copy,
	link,
	linkText,
	background,
	imageOnRight = false,
}: Props) => {
	return (
		<SliceContainer backgroundColor="white">
			<ContentContainer>
				<div
					data-image-on-right={imageOnRight}
					data-color={background}
					className={styles.promo}
				>
					<div className={clsx(styles.image, aspectRatio.container)}>
						{image && (
							<GatsbyImage
								image={image}
								alt={imageAlt ?? ""}
								objectFit="cover"
								objectPosition="center"
							/>
						)}
					</div>

					<div className={styles.promoCopy}>
						{subheading && <h3 className={styles.subheading}>{subheading}</h3>}
						{heading && (
							<h2 className={clsx(typography.heading3, styles.heading)}>
								{heading}
							</h2>
						)}

						{isFilled.richText(copy) && (
							<RichText
								field={copy}
								componentOverrides={{
									paragraph: (props) => (
										<p className={typography.paragraph1}>{props.children}</p>
									),
								}}
							/>
						)}

						{link && (
							<LinkWithArrow className={styles.arrowLink} href={link}>
								{linkText || "Learn More"}
							</LinkWithArrow>
						)}
					</div>
				</div>
			</ContentContainer>
		</SliceContainer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyInteriorPromoFragment>) {
	return {
		image: data.primary.image?.gatsbyImageData as IGatsbyImageData,
		imageAlt: data.primary.image?.alt,
		heading: data.primary.heading?.text,
		subheading: data.primary.subheading?.text,
		copy: data.primary.copy?.richText,
		link: data.primary.link?.url,
		linkText: data.primary.link_text,
		background: data.primary.background_color,
		imageOnRight: data.primary.image_on_right,
	}
}

export const fragment = graphql`
	fragment PageDataBodyInteriorPromo on PrismicPageDataBodyInteriorPromo {
		primary {
			image {
				gatsbyImageData(width: 640, layout: FULL_WIDTH)
				alt
			}
			heading {
				text
			}
			subheading {
				text
			}
			copy {
				richText
			}
			link {
				url
			}
			link_text
			background_color
			image_on_right
		}
	}

	fragment StoriesDataBodyInteriorPromo on PrismicStoriesDataBodyInteriorPromo {
		primary {
			image {
				gatsbyImageData(width: 640)
				alt
			}
			heading {
				text
			}
			subheading {
				text
			}
			copy {
				richText
			}
			link {
				url
			}
			link_text
			background_color
			image_on_right
		}
	}
`

export default PageDataBodyInteriorPromo
