/* eslint-disable @typescript-eslint/no-explicit-any */
import { graphql } from "gatsby"
import { type MapToComponentsProps } from "@walltowall/map-to-components"
import map from "just-map-values"

import type { MapDataToPropsCtx } from "../components/MapSlicesToComponents"

// 1. Import your Slice Module
import * as PageDataBodyStoryCards from "./PageDataBodyStoryCards"
import * as PageDataBodyTextHeading from "./PageDataBodyTextHeading"
import * as PageDataBodyHomeHero from "./PageDataBodyHomeHero"
import * as PageDataBodyRideOptionDetails from "./PageDataBodyRideOptionDetails"
import * as PageDataBodyRideOptionHero from "./PageDataBodyRideOptionHero"
import * as PageDataBodyIllustrationsCtaS from "./PageDataBodyIllustrationsCtas"
import * as PageDataBodyTextWithBackground from "./PageDataBodyTextWithBackground"
import * as PageDataBodyCallToAction from "./PageDataBodyCallToAction"
import * as PageDataBodyFaq from "./PageDataBodyFaq"
import * as PageDataBodyRouteFeatures from "./PageDataBodyRouteFeatures"
import * as PageDataBodyGallery from "./PageDataBodyGallery"
import * as PageDataBodyHeadingWithIllustrations from "./PageDataBodyHeadingWithIllustrations"
import * as PageDataBodyInteriorPromo from "./PageDataBodyInteriorPromo"
import * as PageDataBodyWeRideHero from "./PageDataBodyWeRideHero"
import * as PageDataBodyInteriorPageHero from "./PageDataBodyInteriorPageHero"
import * as PageDataBodyGearPromo from "./PageDataBodyGearPromo"
import * as PageDataBodyRichText from "./PageDataBodyRichText"
import * as PageDataBodyAllNewsPosts from "./PageDataBodyAllNewsPosts"
import * as PageDataBodyInlineGallery from "./PageDataBodyInlineGallery"
import * as StoryDataBodyCopyWithSidebar from "./StoryDataBodyCopyWithSidebar"
import * as PageDataBodyAllStories from "./PageDataBodyAllStories"
import * as PageDataBodyCardGrid from "./PageDataBodyCardGrid"
import * as PageDataBodyCardSlider from "./PageDataBodyCardSlider"
import * as PageDataBodyVideo from "./PageDataBodyVideo"
import * as PageDataBodyForm from "./PageDataBodyForm"
import * as PageDataBodyCountdown from "./PageDataBodyCountdown"
import * as PageDataBodyDownloadAssets from "./PageDataBodyDownloadAssets"
import * as PageDataBodyBioCard from "./PageDataBodyBioCard"
import * as PageDataBodyPartnerGrid from "./PageDataBodyPartnerGrid"

interface SliceModule {
	default: (props: any) => JSX.Element | null
	mapDataToProps?: (ctx: MapDataToPropsCtx) => unknown
}

// 2. Add your slice to this SLICES array
const SLICES: Record<string, SliceModule> = {
	// Page Slices
	PageDataBodyStoryCards,
	PageDataBodyTextWithBackground,
	PageDataBodyTextHeading,
	PageDataBodyHomeHero,
	PageDataBodyRideOptionDetails,
	PageDataBodyRouteFeatures,
	PageDataBodyRideOptionHero,
	PageDataBodyIllustrationsCtaS,
	PageDataBodyCallToAction,
	PageDataBodyFaq,
	PageDataBodyWeRideHero,
	PageDataBodyGallery,
	PageDataBodyHeadingWithIllustrations,
	PageDataBodyInteriorPromo,
	PageDataBodyInteriorPageHero,
	PageDataBodyGearPromo,
	PageDataBodyAllNewsPosts,
	PageDataBodyRichText,
	PageDataBodyInlineGallery,
	PageDataBodyAllStories,
	PageDataBodyCardGrid,
	PageDataBodyCardSlider,
	PageDataBodyVideo,
	PageDataBodyForm,
	PageDataBodyCountdown,
	PageDataBodyDownloadAssets,
	PageDataBodyBioCard,
	PageDataBodyPartnerGrid,

	// News Post Slices
	NewsPostDataBodyRichText: PageDataBodyRichText,
	NewsPostDataBodyCallToAction: PageDataBodyCallToAction,
	NewsPostDataBodyInlineGallery: PageDataBodyInlineGallery,

	// Story Slice
	StoryDataBodyCopyWithSidebar,
	NewsPostDataBodyVideo: PageDataBodyVideo,

	// Stories Slices
	StoriesDataBodyHeadingWithIllustrations: PageDataBodyHeadingWithIllustrations,
	StoriesDataBodyInteriorPromo: PageDataBodyInteriorPromo,
	StoriesDataBodyRichText: PageDataBodyRichText,

	// Story Slices
	StoryDataBodyRichText: PageDataBodyRichText,
	StoryDataBodyCallToAction: PageDataBodyCallToAction,
	StoryDataBodyInlineGallery: PageDataBodyInlineGallery,
	StoryDataBodyVideo: PageDataBodyVideo,
}

export const sliceMap = map(
	SLICES,
	(module) => module.default,
) as MapToComponentsProps["map"]
export const mapDataToProps = map(
	SLICES,
	(module) => module.mapDataToProps,
) as MapToComponentsProps["mapDataToProps"]

// 3. Spread your Slice GraphQL fragment below
export const fragment = graphql`
	fragment PageDataBody on PrismicPageDataBodySlicesType {
		...PageDataBodyStoryCards
		...PageDataBodyTextWithBackground
		...PageDataBodyTextHeading
		...PageDataBodyHomeHero
		...PageDataBodyRideOptionDetails
		...PageDataBodyRouteFeatures
		...PageDataBodyRideOptionHero
		...PageDataBodyIllustrationsCtaS
		...PageDataBodyCallToAction
		...PageDataBodyFaq
		...PageDataBodyWeRideHero
		...PageDataBodyGallery
		...PageDataBodyHeadingWithIllustrations
		...PageDataBodyGearPromo
		...PageDataBodyInteriorPromo
		...PageDataBodyInteriorPageHero
		...PageDataBodyRichText
		...PageDataBodyInlineGallery
		...PageDataBodyCardGrid
		...PageDataBodyCardSlider
		...PageDataBodyVideo
		...PageDataBodyForm
		...PageDataBodyCountdown
		...PageDataBodyDownloadAssets
		...PageDataBodyBioCard
		...PageDataBodyPartnerGrid
	}

	fragment NewsPostDataBody on PrismicNewsPostDataBodySlicesType {
		...NewsPostDataBodyRichText
		...NewsPostDataBodyCallToAction
		...NewsPostDataBodyInlineGallery
		...NewsPostDataBodyVideo
	}

	fragment StoryDataBody on PrismicStoryDataBodySlicesType {
		...StoryDataBodyRichText
		...StoryDataBodyCallToAction
		...StoryDataBodyInlineGallery
		...StoryDataBodyVideo
		...StoryDataBodyCopyWithSidebar
	}

	fragment StoriesDataBody on PrismicStoriesDataBodySlicesType {
		...StoriesDataBodyHeadingWithIllustrations
		...StoriesDataBodyInteriorPromo
		...StoriesDataBodyRichText
	}
`
