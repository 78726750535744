import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import { useCallback, useEffect, useState } from "react"
import { isFilled } from "@prismicio/helpers"
import { RichText } from "../components/RichText"
import { PrevButton } from "../components/PrevButton"
import { NextButton } from "../components/NextButton"
import { DotButton } from "../components/DotButton"
import clsx from "clsx"
import useEmblaCarousel from "embla-carousel-react"
import Autoplay from "embla-carousel-autoplay"

import type { PageDataBodyGalleryFragment } from "../graphql.gen"

import * as emblaStyles from "../styles/EmblaCarousel.module.css"
import * as styles from "./PageDataBodyGallery.module.css"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyGallery = ({ heading, slides }: Props) => {
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [scrollSnaps, setScrollSnaps] = useState([])
	const options = { delay: 4000 }
	const autoplay = Autoplay(options)

	const [emblaRef, embla] = useEmblaCarousel(
		{
			loop: true,
			align: "center",
			slidesToScroll: 1,
		},
		[autoplay],
	)

	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
	const scrollTo = useCallback(
		(index: number) => embla && embla.scrollTo(index),
		[embla],
	)

	const onSelect = useCallback(() => {
		if (!embla) return
		setSelectedIndex(embla.selectedScrollSnap())
		setPrevBtnEnabled(embla.canScrollPrev())
		setNextBtnEnabled(embla.canScrollNext())
	}, [embla])

	useEffect(() => {
		if (!embla) return
		onSelect()
		setScrollSnaps(embla.scrollSnapList())
		embla.on("select", onSelect)
	}, [embla, setScrollSnaps, onSelect])

	return (
		<section className={styles.gallery}>
			{heading && <h2>{heading}</h2>}

			<div
				className={clsx(emblaStyles.embla, styles.gallerySlides)}
				ref={emblaRef}
			>
				<div
					className={clsx(emblaStyles.emblaContainer, styles.slidesContainer)}
				>
					{slides.map((item, index) => (
						<div
							key={index}
							className={clsx(
								emblaStyles.emblaSlide,
								styles.gallerySlide,
								index === selectedIndex && styles.gallerySlideActive,
							)}
						>
							<div
								className={clsx(
									styles.galleryImage,
									index === selectedIndex && styles.galleryImageActive,
								)}
							>
								{item.image && (
									<GatsbyImage
										image={item.image}
										alt={item.imageAlt || ""}
										objectFit="cover"
										objectPosition="center"
									/>
								)}
							</div>

							<div
								className={clsx(
									styles.caption,
									index === selectedIndex && styles.captionActive,
								)}
							>
								{isFilled.richText(item.caption) && (
									<RichText field={item.caption} />
								)}
							</div>
						</div>
					))}
				</div>
			</div>

			<div className={clsx(emblaStyles.emblaControls)}>
				<PrevButton enabled={prevBtnEnabled} onClick={scrollPrev} />
				<div className={emblaStyles.emblaDots}>
					{scrollSnaps.map((_, index) => (
						<DotButton
							key={index}
							selected={index === selectedIndex}
							onClick={() => scrollTo(index)}
						/>
					))}
				</div>
				<NextButton enabled={nextBtnEnabled} onClick={scrollNext} />
			</div>
		</section>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyGalleryFragment>) {
	return {
		heading: data.primary.heading?.text,
		slides: data.items.map((item) => {
			return {
				image: item.image?.gatsbyImageData as IGatsbyImageData,
				imageAlt: item.image?.alt,
				caption: item.caption?.richText,
			}
		}),
	}
}

export const fragment = graphql`
	fragment PageDataBodyGallery on PrismicPageDataBodyGallery {
		primary {
			heading {
				text
			}
		}

		items {
			image {
				gatsbyImageData(width: 1200, layout: FULL_WIDTH)
				alt
			}
			caption {
				richText
			}
		}
	}
`

export default PageDataBodyGallery
