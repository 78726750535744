// extracted by mini-css-extract-plugin
export var answer = "PageDataBodyFaq-module--answer--T+Vew";
export var close = "PageDataBodyFaq-module--close--4osWN";
export var faqItem = "PageDataBodyFaq-module--faqItem--7pYBM";
export var heading = "PageDataBodyFaq-module--heading--fcBOx";
export var link = "PageDataBodyFaq-module--link--LvkeG";
export var open = "PageDataBodyFaq-module--open--T+Mu3";
export var openIndicator = "PageDataBodyFaq-module--openIndicator--ms+iv";
export var paragraph = "PageDataBodyFaq-module--paragraph--XX7Ob";
export var question = "PageDataBodyFaq-module--question--tOOFM";
export var questions = "PageDataBodyFaq-module--questions--BvW3k";
export var richText = "PageDataBodyFaq-module--richText--gbG5k";