import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"

import { IllustrationCard } from "../components/IllustrationCard"
import { SliceContainer } from "../components/SliceContainer"
import { ContentContainer } from "../components/ContentContainer"

import type { PageDataBodyHeadingWithIllustrationsFragment } from "../graphql.gen"
import * as styles from "./PageDataBodyHeadingWithIllustrations.module.css"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyHeadingWithIllustrations = ({
	heading,
	cards,
}: Props) => {
	return (
		<SliceContainer backgroundColor="white">
			<ContentContainer className={styles.innerContainer}>
				{heading && <h2 className={styles.heading}>{heading}</h2>}

				{cards && (
					<div className={styles.cardContainer}>
						{cards.map((card) => (
							<IllustrationCard
								key={card.heading}
								imgAlt={card.imgAlt}
								imgDimensions={card.imgDimensions}
								imgUrl={card.imgUrl}
								title={card.heading}
								copy={card.copy}
							/>
						))}
					</div>
				)}
			</ContentContainer>
		</SliceContainer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyHeadingWithIllustrationsFragment>) {
	return {
		heading: data.primary.heading?.text,
		cards: data.items.map((item) => {
			return {
				heading: item.image_heading?.text,
				imgUrl: item.image?.url,
				imgAlt: item.image?.alt,
				imgDimensions: item.image?.dimensions,
				copy: item.copy_text?.richText,
			}
		}),
	}
}

export const fragment = graphql`
	fragment PageDataBodyHeadingWithIllustrations on PrismicPageDataBodyHeadingWithIllustrations {
		primary {
			heading {
				text
			}
		}

		items {
			image_heading {
				text
			}
			image {
				alt
				dimensions {
					width
					height
				}
				url
			}
			copy_text {
				richText
			}
		}
	}

	fragment StoriesDataBodyHeadingWithIllustrations on PrismicStoriesDataBodyHeadingWithIllustrations {
		primary {
			heading {
				text
			}
		}

		items {
			image_heading {
				text
			}
			image {
				alt
				dimensions {
					width
					height
				}
				url
			}
			copy_text {
				richText
			}
		}
	}
`

export default PageDataBodyHeadingWithIllustrations
