export const ClipPathSVG = () => (
	<svg width={0} height={0} style={{ display: "block" }}>
		<defs>
			<clipPath id="gearPath" clipPathUnits="objectBoundingBox">
				<path
					d="M0.994,0.63 C0.954,0.616,0.911,0.632,0.896,0.667 C0.882,0.702,0.901,0.743,0.94,0.762 C0.93,0.778,0.92,0.793,0.909,0.808 C0.877,0.78,0.831,0.778,0.804,0.804 C0.778,0.831,0.78,0.877,0.808,0.909 C0.793,0.92,0.778,0.93,0.762,0.94 C0.743,0.901,0.702,0.882,0.667,0.896 C0.632,0.911,0.616,0.954,0.63,0.994 C0.613,0.999,0.594,1,0.576,1 C0.574,0.962,0.543,0.928,0.505,0.928 C0.467,0.928,0.436,0.962,0.434,1 C0.415,1,0.397,0.999,0.379,0.994 C0.393,0.954,0.378,0.911,0.343,0.896 C0.308,0.882,0.266,0.901,0.248,0.94 C0.232,0.93,0.216,0.92,0.201,0.909 C0.23,0.877,0.232,0.831,0.205,0.804 C0.178,0.778,0.133,0.78,0.101,0.808 C0.09,0.793,0.079,0.778,0.07,0.762 C0.109,0.743,0.128,0.702,0.113,0.667 C0.099,0.632,0.056,0.616,0.016,0.63 C0.011,0.613,0.007,0.594,0.005,0.576 C0.047,0.574,0.081,0.543,0.081,0.505 C0.081,0.467,0.047,0.436,0.005,0.434 C0.007,0.415,0.011,0.397,0.016,0.379 C0.056,0.393,0.099,0.378,0.113,0.343 C0.128,0.308,0.109,0.266,0.07,0.248 C0.079,0.232,0.09,0.216,0.101,0.201 C0.133,0.23,0.178,0.232,0.205,0.205 C0.232,0.178,0.23,0.133,0.201,0.101 C0.216,0.09,0.232,0.079,0.248,0.07 C0.266,0.108,0.308,0.128,0.343,0.113 C0.377,0.099,0.393,0.056,0.379,0.017 C0.379,0.016,0.379,0.016,0.379,0.016 C0.397,0.011,0.415,0.007,0.434,0.005 C0.436,0.047,0.467,0.081,0.505,0.081 C0.543,0.081,0.574,0.047,0.576,0.005 C0.594,0.007,0.613,0.011,0.63,0.016 C0.616,0.056,0.632,0.099,0.667,0.113 C0.702,0.128,0.743,0.108,0.762,0.07 C0.778,0.079,0.793,0.09,0.808,0.101 C0.78,0.133,0.778,0.178,0.804,0.205 C0.831,0.232,0.877,0.23,0.909,0.201 C0.92,0.216,0.93,0.232,0.94,0.248 C0.901,0.266,0.882,0.308,0.896,0.343 C0.911,0.378,0.954,0.393,0.994,0.379 C0.999,0.397,1,0.415,1,0.434 C0.962,0.436,0.928,0.467,0.928,0.505 C0.928,0.543,0.962,0.574,1,0.576 C1,0.594,0.999,0.613,0.994,0.63"
					stroke="#fff0"
				/>
			</clipPath>
		</defs>
	</svg>
)
