import { useStaticQuery, graphql } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { SiteSettingsQuery } from "../graphql.gen"

export function useSiteSettings() {
	const staticData = useStaticQuery<SiteSettingsQuery>(graphql`
		query SiteSettings {
			prismicSettings {
				_previewable
				data {
					site_name {
						text
					}
					site_description {
						text
					}
					site_tagline {
						text
					}
					twitter_card {
						url(imgixParams: { width: 800, height: 418, q: 75 })
					}
					twitter_username
					open_graph_card {
						url(imgixParams: { width: 1200, height: 630, q: 75 })
					}
				}
			}
		}
	`)
	const { data: mergedData } = useMergePrismicPreviewData(staticData)

	const settings = mergedData.prismicSettings

	return {
		siteName: settings?.data.site_name?.text ?? "Rush to Crush Cancer",
		siteDescription: settings?.data.site_description?.text,
		siteTagline: settings?.data.site_tagline?.text,
		twitter: {
			username: settings?.data.twitter_username,
			cardImageUrl: settings?.data.twitter_card?.url,
		},
		openGraph: {
			cardImageUrl: settings?.data.open_graph_card?.url,
		},
	}
}
