import { useState, useEffect, useMemo } from 'react'
import { graphql } from "gatsby"
import { DateTime } from 'luxon'
import clsx from 'clsx'
import Donut from 'react-svg-donuts';
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"

import type { PageDataBodyCountdownFragment } from "../graphql.gen"

import * as styles from "./PageDataBodyCountdown.module.css"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyCountdown = ({
  heading,
  eventDate
}: Props) => {
  const date = useMemo(() => DateTime.fromJSDate(new Date(eventDate)), [eventDate])

  const [digits, setDigits] = useState({
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })
  const [progress, setProgress] = useState({
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })
  const [showCountdown, setShowCountdown] = useState(false)

  useEffect(() => {
    if (date.isValid) {
      const diff = date.diff(DateTime.local()).toObject()?.milliseconds
      setShowCountdown(diff > 0)

      getDigits()

      const timer = setInterval(() => {
        getDigits()
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [date])

  const getDigits = () => {
    const now = DateTime.local()
    const diff = date.diff(now, [
      'months',
      'days',
      'hours',
      'minutes',
      'seconds',
    ])

    const { months, days, hours, minutes, seconds } = diff.toObject()
    setDigits({
      months,
      days,
      hours,
      minutes,
      seconds: parseInt(seconds),
    })

    setProgress({
      months: months / 12 * 100,
      days: days / 30 * 100,
      hours: hours / 24 * 100,
      minutes: minutes / 60 * 100,
      seconds: seconds / 60 * 100
    })
  }

  const renderDigit = (digit) => (
    <span>{digit}</span>
  );

  return date.isValid && showCountdown ? (
    <section className={styles.countdown}>
      {heading && <h3>{heading}</h3>}

      <div className={styles.donutContainer}>
        {Object.keys(progress).map((unit) => (
          <div
            key={unit}
            className={clsx(
              styles.donut,
              styles[unit],
              digits[unit] === 0 && styles.finished
            )}
          >
            <Donut
              progress={digits[unit] === 0 ? 0 : progress[unit]}
              onRender={() => renderDigit(digits[unit])}
            />

            <h4>{unit}</h4>
          </div>
        ))}
      </div>
    </section>
  ) : null
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyCountdownFragment>) {
	return {
    heading: data.primary.heading?.text,
    eventDate: data.primary.event_date
  }
}

export const fragment = graphql`
  fragment PageDataBodyCountdown on PrismicPageDataBodyCountdown {
    primary {
      heading {
        text
      }
      event_date
    }
  }
`

export default PageDataBodyCountdown
