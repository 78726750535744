import { useRef, useState } from "react"
import {
	type GetServerDataProps,
	graphql,
	type PageProps,
	HeadProps,
} from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import type { RichTextField } from "@prismicio/types"
import { isFilled } from "@prismicio/helpers"
import clsx from "clsx"

import { RichText } from "../components/RichText"
import { Button } from "../components/Button"
import { SEO } from "../components/SEO"
import { useSiteSettings } from "../hooks/useSiteSettings"
import { Header } from "../components/Header"
import { getApprovedSubmissions } from "../airtable/submissions"

import type { WhyIRidePageQuery } from "../graphql.gen"
import type { Submission, SubmissionListResult } from "../airtable/types"

import whySquare from "../assets/download-square.jpg"
import whyVertical from "../assets/download-vertical.jpg"

import * as styles from "./why-i-ride.module.css"
import * as typography from "../styles/typography.module.css"

type SubimissionsGridProps = {
	copy?: RichTextField
	submissions: Submission[]
	initialOffset?: string
	setSubmissions: React.Dispatch<
		React.SetStateAction<ServerProps["submissions"]["records"]>
	>
}

const SubmissionsGrid = ({
	copy,
	submissions,
	initialOffset,
	setSubmissions,
}: SubimissionsGridProps) => {
	const [offset, setOffset] = useState(initialOffset)
	const [loading, setLoading] = useState(false)

	//  TODO: Create a loading indicator when loading more submissions.
	//  Could use some lightweight data-fetching library like `swr` here.
	const loadMoreSubmissions = async () => {
		setLoading(true)

		const urlParams = new URLSearchParams()
		if (offset) urlParams.set("offset", offset)

		const res = await fetch("/api/submissions?" + urlParams.toString())
		const data = (await res.json()) as SubmissionListResult

		setLoading(false)
		setSubmissions((prev) => [...prev, ...data.records])
		setOffset(data.offset)
	}

	return (
		<div className={styles.theWall}>
			{isFilled.richText(copy) && (
				<RichText
					field={copy}
					componentOverrides={{
						heading2: (props) => (
							<h2 className={typography.heading3}>{props.children}</h2>
						),
						heading3: (props) => (
							<h3 className={typography.heading4}>{props.children}</h3>
						),
					}}
				/>
			)}

			<ul className={styles.reasons}>
				{submissions?.map((submission) => (
					<li className={styles.reason} key={submission.id}>
						<h4>{submission.fields.Reason}</h4>
					</li>
				))}
			</ul>

			{Boolean(offset) && (
				<div className={styles.loadMore}>
					<Button
						className={styles.loadMore}
						onClick={loadMoreSubmissions}
						disabled={loading}
					>
						{loading ? "Loading..." : "Load More"}
					</Button>
				</div>
			)}
		</div>
	)
}

type ServerProps = Awaited<ReturnType<typeof getServerData>>["props"]

const WhyIRidePage = ({
	data,
	serverData,
}: PageProps<WhyIRidePageQuery, never, Window["location"], ServerProps>) => {
	const [activePage, setActivePage] = useState("edit")
	const [message, setMessage] = useState("")
	const [submissions, setSubmissions] = useState(serverData.submissions.records)
	const [adding, setAdding] = useState(false)

	const canvas = useRef<HTMLCanvasElement>(null)
	const verticalCanvas = useRef<HTMLCanvasElement>(null)

	const page = data.prismicWhyIRide
	const title = page?.data?.title?.text
	const background = page?.data?.background_illustration?.url
	const backgroundAlt = page?.data?.background_illustration?.alt
	const instructions = page?.data?.input_instructions
	const buttonText = page?.data?.button_text
	const copy = page?.data?.wall_copy?.richText
	const downloadsHeading = page?.data?.downloads_heading?.text
	const downloadsInstructions = page?.data?.downloads_instructions?.richText

	// create a canvas with user inputted text
	const renderCanvas = (
		item: React.RefObject<HTMLCanvasElement>,
		width: number,
		height: number,
	) => {
		setActivePage("share")
		const c = item?.current?.getContext("2d")

		if (c) {
			const bg = new Image()
			c.textAlign = "center"

			bg.onload = () => {
				c.drawImage(bg, 0, 0, width, height)
				c.font = "bold 50pt Byker"
				c.fillStyle = "#031d47"

				const maxWidth = 680
				const lineHeight = 80
				const x = height > width ? 540 : 533
				const y = height > width ? 1015 : 645

				// display wrapped text on canvas
				wrapText(c, message, x, y, maxWidth, lineHeight)
			}

			if (height > width) {
				bg.src = whyVertical
			} else {
				bg.src = whySquare
			}
		}
	}

	// take user input and wrap text to fit on canvas
	const wrapText = (
		context: CanvasRenderingContext2D,
		text: string,
		x: number,
		y: number,
		maxWidth: number,
		lineHeight: number,
	) => {
		const words = text.split(" ")
		let line = ""
		const lines = []

		// show manual line breaks on new lines
		for (let n = 0; n < words.length; n++) {
			const testLine = line + words[n] + " "
			const metrics = context.measureText(testLine)
			const testWidth = metrics.width

			if (testWidth > maxWidth && n > 0) {
				lines.push(line.trim())
				line = words[n] + " "
			} else {
				line = testLine
			}
		}

		lines.push(line.trim())

		// calculate vertical positioning of each line of text
		for (let i = 0; i < lines.length; i++) {
			const start = -(lineHeight / 2) + (lineHeight / 2) * i
			const linesAfter = lines.length - (i + 1)
			const verticalDifference = start - (lineHeight / 2) * linesAfter

			const lineY = y + verticalDifference

			context.fillText(lines[i], x, lineY)
		}
	}

	// download vertical or square png
	const download = (item: React.RefObject<HTMLCanvasElement>) => {
		const image = item?.current?.toDataURL("image/png", 1.0)

		if (image) {
			const a = document.createElement("a")
			a.href = image
			a.download = "why-i-ride"

			const clickHandler = () => {
				setTimeout(() => {
					URL.revokeObjectURL(image)
					a.removeEventListener("click", clickHandler)
				}, 150)
			}

			a.addEventListener("click", clickHandler, false)
			a.click()
		}
	}

	//  TODO: Error handling and request cancellation (if user navigates away
	//  mid-request). Data fetching lib could help simplify here too.
	const saveReason = async () => {
		if (!message) return

		setAdding(true)
		const res = await fetch("/api/submissions", {
			method: "POST",
			body: JSON.stringify({ reason: message }),
			headers: { "Content-Type": "application/json" },
		})

		if (res.status !== 200) {
			console.error("Error: Reason could not be saved.")

			return
		}

		// Do anything we need to with the data from airtable.
		const _data = (await res.json()) as Submission

		setAdding(false)
		setSubmissions([_data, ...submissions])
		setMessage("")
	}

	return (
		<>
			<Header />

			<div className={styles.wallHero}>
				{background && (
					<img
						className={styles.background}
						src={background}
						role="presentation"
						alt={backgroundAlt || ""}
					/>
				)}

				{title && <h1>{title}</h1>}

				<div
					className={clsx(
						styles.wallEdit,
						activePage === "edit" && styles.show,
					)}
				>
					<input
						type="text"
						placeholder={instructions || ""}
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
					<Button
						disabled={!message}
						onClick={() => {
							renderCanvas(canvas, 1080, 1080)
							renderCanvas(verticalCanvas, 1080, 1920)
						}}
					>
						{buttonText}
					</Button>
				</div>

				<div
					className={clsx(
						styles.wallShare,
						activePage === "share" && styles.show,
					)}
				>
					<div className={styles.cardPreview}>
						<canvas width="1080" height="1080" ref={canvas}></canvas>
					</div>

					<div className={clsx(styles.cardPreview, styles.cardVertical)}>
						<canvas width="1080" height="1920" ref={verticalCanvas}></canvas>
					</div>

					<div className={styles.instructions}>
						{downloadsHeading && <h5>{downloadsHeading}</h5>}

						{downloadsInstructions && (
							<RichText field={downloadsInstructions} />
						)}

						<div className={styles.downloadButtons}>
							<button
								className={styles.download}
								onClick={() => download(canvas)}
							>
								square
							</button>

							<button
								className={styles.download}
								onClick={() => download(verticalCanvas)}
							>
								vertical
							</button>
						</div>

						<div className={styles.actionButtons}>
							<Button
								className={styles.addToWall}
								onClick={saveReason}
								disabled={adding}
							>
								{adding ? "Adding..." : "Add to Wall"}
							</Button>

							<Button
								className={styles.goBack}
								onClick={() => setActivePage("edit")}
							>
								Go Back
							</Button>
						</div>
					</div>
				</div>
			</div>

			<SubmissionsGrid
				submissions={submissions}
				initialOffset={serverData.submissions.offset}
				setSubmissions={setSubmissions}
				copy={copy}
			/>
		</>
	)
}

export const Head = ({ data }: HeadProps<WhyIRidePageQuery>) => {
	const settings = useSiteSettings()
	const page = data.prismicWhyIRide

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle="Why I Ride"
			meta={{
				description: page?.data.meta_description?.text,
				title: page?.data.meta_title?.text,
			}}
			twitter={{
				username: settings.twitter.username,
				cardImageUrl:
					page?.data.twitter_card?.url ?? settings.twitter.cardImageUrl,
			}}
			openGraph={{
				cardImageUrl:
					page?.data.open_graph_image?.url ?? settings.openGraph.cardImageUrl,
			}}
		/>
	)
}

export default withPrismicPreview(WhyIRidePage)

export async function getServerData(_ctx: GetServerDataProps) {
	const submissions = await getApprovedSubmissions()

	return {
		props: { submissions },
		headers: {
			"Cache-Control": "public, s-maxage=60, stale-while-revalidate=604800",
		},
	}
}

export const query = graphql`
	query WhyIRidePage {
		prismicWhyIRide {
			_previewable
			data {
				title {
					text
				}
				button_text
				input_instructions
				wall_copy {
					richText
				}
				downloads_heading {
					text
				}
				downloads_instructions {
					richText
				}
				background_illustration {
					url
					alt
				}
				meta_description {
					text
				}
				meta_title {
					text
				}
				open_graph_image {
					url(imgixParams: { width: 1200, height: 630, q: 75 })
				}
				twitter_card {
					url(imgixParams: { width: 800, height: 418, q: 75 })
				}
			}
		}
	}
`
