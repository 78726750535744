// extracted by mini-css-extract-plugin
export var buttons = "PageDataBodyCardSlider-module--buttons--+He93";
export var container = "PageDataBodyCardSlider-module--container--utr0b";
export var dot = "PageDataBodyCardSlider-module--dot--JbN0S";
export var dots = "PageDataBodyCardSlider-module--dots--Sl2zU";
export var header = "PageDataBodyCardSlider-module--header--wXk7p";
export var next = "PageDataBodyCardSlider-module--next--FT8w5";
export var prev = "PageDataBodyCardSlider-module--prev--U0lfA";
export var slide = "PageDataBodyCardSlider-module--slide--dd-fL";
export var slider = "PageDataBodyCardSlider-module--slider--V934n";
export var textContainer = "PageDataBodyCardSlider-module--textContainer--hxhzy";