import { graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import * as typography from "../styles/typography.module.css"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { SliceContainer } from "../components/SliceContainer"
import clsx from "clsx"
import type { PageDataBodyCardSliderFragment } from "../graphql.gen"
import { Link } from "../components/Link"

import { useCallback, useEffect, useRef, useState } from "react"
import useEmblaCarousel from "embla-carousel-react"
import { PrevButton } from "../components/PrevButton"
import { NextButton } from "../components/NextButton"
import * as emblaStyles from "../styles/EmblaCarousel.module.css"
import * as cardStyles from "./PageDataBodyStoryCards.module.css"
import * as styles from "./PageDataBodyCardSlider.module.css"

type Props = ReturnType<typeof mapDataToProps> & {
	className?: string
}

export const PageDataBodyCardSlider = ({
	heading,
	cards,
	className,
}: Props) => {
	const [emblaRef, embla] = useEmblaCarousel({
		skipSnaps: false,
		align: "center",
		startIndex: 1,
		slidesToScroll: 1,
	})

	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [scrollSnaps, setScrollSnaps] = useState([])
	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
	const scrollTo = useCallback(
		(index) => embla && embla.scrollTo(index),
		[embla],
	)

	const onSelect = useCallback(() => {
		if (!embla) return
		setSelectedIndex(embla.selectedScrollSnap())
		setPrevBtnEnabled(embla.canScrollPrev())
		setNextBtnEnabled(embla.canScrollNext())
	}, [embla, setSelectedIndex])

	useEffect(() => {
		if (!embla) return
		onSelect()
		embla.on("select", onSelect)
		setScrollSnaps(embla.scrollSnapList())
	}, [embla, setScrollSnaps, onSelect])

	return (
		<SliceContainer
			className={clsx(className, styles.container)}
			backgroundColor="red"
			data-background="red"
		>
			{heading && (
				<h2 className={clsx(typography.heading2, styles.header)}>{heading}</h2>
			)}
			{cards && (
				<div className={clsx(emblaStyles.embla, styles.slider)} ref={emblaRef}>
					<div className={clsx(emblaStyles.emblaContainer)}>
						{cards.map((card, index) => {
							const Component = card.href ? Link : "div"

							return (
								<div key={index} className={clsx("embla__slide", styles.slide)}>
									<div className="embla__slide__inner">
										<Component
											key={index}
											className={cardStyles.cardContainer}
											href={card.href}
										>
											{card.image && (
												<div className={cardStyles.imageContainer}>
													<GatsbyImage
														image={card.image}
														className={clsx(cardStyles.inner)}
														alt={card.imageAlt ?? ""}
													/>
												</div>
											)}

											<div
												className={clsx(
													cardStyles.textContainer,
													styles.textContainer,
												)}
											>
												{card.heading && (
													<h3 className={cardStyles.cardHeading}>
														{card.kicker && (
															<span className={cardStyles.kicker}>
																{card.kicker}
															</span>
														)}
														{card.heading}
													</h3>
												)}

												{card.subheading && (
													<h4 className={cardStyles.cardSubheading}>
														{card.subheading}
													</h4>
												)}
											</div>
										</Component>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			)}
			<div className={styles.buttons}>
				<PrevButton
					enabled={prevBtnEnabled}
					onClick={scrollPrev}
					className={styles.prev}
				/>
				<NextButton
					enabled={nextBtnEnabled}
					onClick={scrollNext}
					className={styles.next}
				/>
			</div>
			<div className={styles.dots}>
				{cards.map((_, index) => (
					<button
						key={index}
						data-selected={index === selectedIndex}
						onClick={() => scrollTo(index)}
						className={styles.dot}
					/>
				))}
			</div>
		</SliceContainer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyCardSliderFragment>) {
	return {
		heading: data.primary.heading?.text,
		cards: data.items.map((item) => ({
			image: item.image?.gatsbyImageData as IGatsbyImageData,
			imageAlt: item.image?.alt,
			kicker: item.kicker_text,
			heading: item.heading?.text,
			subheading: item.subheading?.text,
			href: item.link?.url,
		})),
	}
}

export const fragment = graphql`
	fragment PageDataBodyCardSlider on PrismicPageDataBodyCardSlider {
		primary {
			heading {
				text
			}
		}

		items {
			image {
				alt
				gatsbyImageData(
					width: 700
					layout: FULL_WIDTH
					breakpoints: [234, 500, 700]
				)
			}
			kicker_text
			heading {
				text
			}
			subheading {
				text
			}
			link {
				url
				target
			}
		}
	}
`

export default PageDataBodyCardSlider
