// extracted by mini-css-extract-plugin
export var close = "PageDataBodyRouteFeatures-module--close--8jyMP";
export var feature = "PageDataBodyRouteFeatures-module--feature--VNI5H";
export var feature1 = "PageDataBodyRouteFeatures-module--feature1--NPESa";
export var feature2 = "PageDataBodyRouteFeatures-module--feature2--i8NWd";
export var feature3 = "PageDataBodyRouteFeatures-module--feature3--STqz2";
export var featureImage = "PageDataBodyRouteFeatures-module--featureImage--74KX-";
export var featureList = "PageDataBodyRouteFeatures-module--featureList--VTHLf";
export var landmarkCards = "PageDataBodyRouteFeatures-module--landmarkCards--P7E9z";
export var landmarkContainer = "PageDataBodyRouteFeatures-module--landmarkContainer--L5Hj9";
export var landmarkPreview = "PageDataBodyRouteFeatures-module--landmarkPreview--qRA+t";
export var landmarkSlide = "PageDataBodyRouteFeatures-module--landmarkSlide--EoG0Y";
export var landmarkSlider = "PageDataBodyRouteFeatures-module--landmarkSlider--FDQSq";
export var largeNumber = "PageDataBodyRouteFeatures-module--largeNumber--GIjiK";
export var mouseFollow = "PageDataBodyRouteFeatures-module--mouseFollow--HDxJb";
export var mouseFollowShow = "PageDataBodyRouteFeatures-module--mouseFollowShow--+xdy4";
export var next = "PageDataBodyRouteFeatures-module--next--Olfoi";
export var prev = "PageDataBodyRouteFeatures-module--prev--EjL+T";
export var routeFeatures = "PageDataBodyRouteFeatures-module--routeFeatures--NX5Ma";
export var routeHeading = "PageDataBodyRouteFeatures-module--routeHeading--7BoKn";
export var routeLink = "PageDataBodyRouteFeatures-module--routeLink--F+xu2";
export var show = "PageDataBodyRouteFeatures-module--show--K4FOi";
export var showUp = "PageDataBodyRouteFeatures-module--showUp--zaQJG";
export var viewAll = "PageDataBodyRouteFeatures-module--viewAll--9y8av";