/**
 * This file contains a template for all Page documents in your Prismic
 * repository. It uses Gatsby's File System Route API to automatically generate
 * a page for each document using its `url` field. The `url` field is computed
 * using your app's Link Resolver.
 *
 * This template supports Prismic previews using the `withPrismicPreview` higher
 * order component.
 *
 * @see https://www.gatsbyjs.com/docs/reference/routing/file-system-route-api/
 */

import { graphql, HeadProps, type PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { MapSlicesToComponents } from "../components/MapSlicesToComponents"
import { SEO } from "../components/SEO"
import { useSiteSettings } from "../hooks/useSiteSettings"
import { Header } from "../components/Header"

import type { PageTemplateQuery } from "../graphql.gen"

const PageTemplate = ({ data }: PageProps<PageTemplateQuery>) => {
	const page = data.prismicPage

	return (
		<>
			<Header />
			<MapSlicesToComponents slices={page?.data.body} />
		</>
	)
}

export const Head = ({ data }: HeadProps<PageTemplateQuery>) => {
	const settings = useSiteSettings()
	const page = data.prismicPage

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle={page?.uid === "home" ? undefined : page?.data.title?.text}
			meta={{
				description: page?.data.meta_description?.text,
				title: page?.data.meta_title?.text,
			}}
			twitter={{
				username: settings.twitter.username,
				cardImageUrl:
					page?.data.twitter_card?.url ?? settings.twitter.cardImageUrl,
			}}
			openGraph={{
				cardImageUrl:
					page?.data.open_graph_image?.url ?? settings.openGraph.cardImageUrl,
			}}
		/>
	)
}

/**
 * When a Prismic preview session is active, `withPrismicPreview` will
 * automatically provide your template with updated preview content. As editors
 * edit and save content in the Prismic writing room, the page will
 * automatically refresh to display the edited content.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#withprismicpreview
 */
export default withPrismicPreview(PageTemplate)

export const query = graphql`
	query PageTemplate($id: String!) {
		prismicPage(id: { eq: $id }) {
			...PageData
		}
	}

	fragment PageData on PrismicPage {
		_previewable
		uid
		data {
			title {
				text
			}
			meta_description {
				text
			}
			meta_title {
				text
			}
			open_graph_image {
				url(imgixParams: { width: 1200, height: 630, q: 75 })
			}
			twitter_card {
				url(imgixParams: { width: 800, height: 418, q: 75 })
			}

			body {
				__typename
				...PageDataBody
			}
		}
	}
`
