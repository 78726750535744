import clsx from "clsx"
import { type IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./GearGatsbyImage.module.css"

export interface GearGatsbyImageProps
	extends React.ComponentPropsWithoutRef<"div"> {
	image: IGatsbyImageData
	alt?: string
}

export const GearGatsbyImage = ({
	className,
	image,
	alt,
	...props
}: GearGatsbyImageProps) => {
	return (
		<div className={clsx(styles.wrapper, className)} {...props}>
			<GatsbyImage
				image={image}
				alt={alt ?? ""}
				objectFit="cover"
				objectPosition="center"
			/>
		</div>
	)
}
