import { useEffect, useId, useState, Fragment } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { PrismicLink } from "@prismicio/react"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { useLocation, globalHistory } from "@gatsbyjs/reach-router"
import clsx from "clsx"
import type { HeaderDataQuery } from "../graphql.gen"
import { useScrollDirection } from "../hooks/useScrollDirection"

import { MenuButton } from "./MenuButton"
import { SearchExpand } from "./SearchExpand"
import logoURL from "../assets/logo-large.svg"
import dropdownURL from "../assets/arrow-down.svg"
import * as styles from "./Header.module.css"

function useHeaderData() {
	const staticData = useStaticQuery<HeaderDataQuery>(graphql`
		query HeaderData {
			prismicSettings {
				_previewable
				data {
					primary_navigation {
						...NavigationMenuData
					}
					secondary_navigation {
						...NavigationMenuData
					}
					site_tagline {
						text
					}
				}
			}
		}
	`)

	const { data: mergedData } = useMergePrismicPreviewData(staticData)

	const data = mergedData.prismicSettings?.data
	const siteTagline = data?.site_tagline?.text
	const primaryMenu = data?.primary_navigation?.document
	const secondaryMenu = data?.secondary_navigation?.document
	if (
		primaryMenu?.__typename !== "PrismicNavigation" ||
		secondaryMenu?.__typename !== "PrismicNavigation"
	) {
		throw new Error(
			"Did not receive a PrismicNavigation when we queried for it.",
		)
	}

	type Item = NonNullable<typeof primaryMenu["data"]>["body"][number]

	function mapDataToMenu(item: Item) {
		return {
			href: item.primary.link_url?.url,
			text: item.primary.link_text,
			hasSubmenu: item.items.length > 0,
			items: item.items.map((secondaryItem) => ({
				href: secondaryItem.link_url?.url,
				kicker: secondaryItem.kicker_text,
				text: secondaryItem.link_text,
				featured: secondaryItem.featured,
			})),
		}
	}

	return {
		siteTagline,
		primaryMenu: primaryMenu.data.body.map(mapDataToMenu),
		secondaryMenu: secondaryMenu.data.body.map(mapDataToMenu),
	}
}

interface SubItem {
	kicker?: string
	text?: string
	href?: string
	featured?: boolean
}
interface NavItemProps {
	href?: string
	text?: string
	hasSubMenu?: boolean
	navItems?: SubItem[] | undefined
	tagline?: string
	onClick?: () => void
	hoverable: boolean
	setHoverable: () => void
}
const PrimaryNavLink = ({
	href,
	text,
	hasSubMenu,
	navItems,
	tagline,
	onClick,
	hoverable,
	setHoverable,
}: NavItemProps) => {
	const location = useLocation()
	const isActive = location.pathname.includes(href as string)
	const [open, setOpen] = useState(false)
	const id = useId()

	return (
		<div
			className={clsx(
				styles.primaryLinkWrapper,
				hoverable && styles.focusInside,
			)}
		>
			<PrismicLink
				href={href}
				onClick={onClick}
				onMouseLeave={() => setHoverable(true)}
				className={clsx(
					styles.primaryLink,
					hoverable && styles.hoverable,
					isActive && styles.primaryLinkActive,
					hasSubMenu ? styles.subMenuArrow : "",
				)}
			>
				{text}
			</PrismicLink>

			{hasSubMenu && (
				<>
					<button
						className={clsx(
							styles.childrenToggle,
							open ? styles.childrenToggleOpen : "",
						)}
						onClick={() => setOpen(!open)}
						aria-label="Toggle Children Navigation Items"
						aria-controls={`${id}-${text?.replace(/\W/g, "-")}`}
						aria-expanded={open}
					>
						<img role="presentation" src={dropdownURL} />
					</button>

					<nav
						id={`${id}-${text?.replace(/\W/g, "-")}`}
						aria-label={`${text} sub-navigation`}
						className={clsx(styles.subMenu, open ? styles.subMenuOpen : "")}
					>
						<div>
							<div>
								{navItems &&
									navItems.map((subItem) => (
										<Fragment key={subItem.text}>
											{subItem.featured && (
												<PrismicLink
													href={subItem.href}
													className={
														subItem.featured
															? styles.featuredLink
															: styles.secondaryLink
													}
												>
													{subItem.kicker && (
														<span className={styles.kicker}>
															{subItem.kicker}
														</span>
													)}

													{subItem.text}
												</PrismicLink>
											)}
										</Fragment>
									))}
							</div>
							<div>
								{navItems &&
									navItems.map((subItem) => (
										<Fragment key={subItem.text}>
											{!subItem.featured && (
												<PrismicLink
													href={subItem.href}
													className={
														subItem.featured
															? styles.featuredLink
															: styles.secondaryLink
													}
												>
													{subItem.kicker && (
														<span className={styles.kicker}>
															{subItem.kicker}
														</span>
													)}

													{subItem.text}
												</PrismicLink>
											)}
										</Fragment>
									))}
							</div>
						</div>
					</nav>
				</>
			)}
		</div>
	)
}

export const Header = () => {
	const { siteTagline, primaryMenu, secondaryMenu } = useHeaderData()
	const [open, setOpen] = useState(false)
	const [hoverable, setHoverable] = useState(false)
	const scrollDirection = useScrollDirection()

	useEffect(
		() =>
			globalHistory.listen(({ action }) => {
				if (action === "PUSH") {
					setOpen(false)
				}
			}),
		[],
	)

	const hoverDelay = () => {
		setTimeout(() => {
			setHoverable(true)
		}, 1000)
	}

	// Prevents a sub-menu from persisting after mouse interaction since our
	// header never unmounts.
	function blurOnClick() {
		const activeEl = document.activeElement as HTMLElement | undefined
		activeEl?.blur()
		setOpen(false)
	}

	return (
		<header
			className={clsx(
				styles.siteHeader,
				scrollDirection === "down" && styles.hidden,
			)}
			onMouseEnter={hoverDelay}
		>
			<Link to="/" className={styles.siteLogo}>
				<img
					src={logoURL}
					alt="Rush to Crush Cancer"
					loading="eager"
					decoding="async"
				/>
			</Link>

			<MenuButton open={open} onClick={() => setOpen(!open)} />

			<div
				className={clsx(
					styles.siteNavigation,
					open ? styles.siteNavigationOpen : "",
				)}
			>
				<nav
					aria-label="Secondary Navigation"
					className={styles.navigationSecondary}
				>
					{secondaryMenu.map((item) => (
						<PrismicLink
							href={item.href}
							key={item.text}
							className={styles.headerButton}
						>
							{item.text}
						</PrismicLink>
					))}

					<SearchExpand />
				</nav>

				<nav
					aria-label="Primary Navigation"
					className={styles.navigationPrimary}
				>
					{primaryMenu.map((item) => (
						<PrimaryNavLink
							key={item.text}
							href={item.href}
							text={item.text}
							hasSubMenu={item.hasSubmenu}
							navItems={item?.items}
							tagline={siteTagline}
							onClick={item.hasSubmenu ? blurOnClick : undefined}
							hoverable={hoverable}
							setHoverable={setHoverable}
						/>
					))}
				</nav>
			</div>
		</header>
	)
}
