import React from "react"

export const Arrow = (props: React.ComponentProps<"svg">) => {
	return (
		<svg
			viewBox="0 0 28 22"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g fill="none" fillRule="evenodd" transform="translate(.817792 1.5)">
				<path
					d="m0 8.06207h22.330207v2.87586h-22.330207z"
					fill="currentColor"
				/>
				<path
					d="m1.60709821-.5956101 10.04808039 10.04808035-2.03354011 2.03354005-.03763849-.0370103-8.14607003 8.1466101-2.03354007-2.0335401 8.1456101-8.14707-7.97644187-7.97707003z"
					fill="currentColor"
					transform="translate(14.68582)"
				/>
			</g>
		</svg>
	)
}
