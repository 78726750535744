// extracted by mini-css-extract-plugin
export var container = "PageDataBodyForm-module--container--ONlxw";
export var copy = "PageDataBodyForm-module--copy--dB8UO";
export var error = "PageDataBodyForm-module--error--Pn8Tr";
export var field = "PageDataBodyForm-module--field--FxdgY";
export var form = "PageDataBodyForm-module--form--er3Sz";
export var fullWidth = "PageDataBodyForm-module--fullWidth--se4Ki";
export var heading3 = "PageDataBodyForm-module--heading3--ldttM";
export var label = "PageDataBodyForm-module--label--dUAPx";
export var paragraph = "PageDataBodyForm-module--paragraph--OpxMD";
export var submit = "PageDataBodyForm-module--submit--nb120";
export var submitError = "PageDataBodyForm-module--submitError--y+-Nf";
export var success = "PageDataBodyForm-module--success--vL0do";
export var text = "PageDataBodyForm-module--text--aUSuS";
export var textarea = "PageDataBodyForm-module--textarea--fx7QQ";