import {
	type PrismicRichTextProps,
	PrismicRichText,
	PrismicLink,
} from "@prismicio/react"

import { linkResolver } from "../prismic/linkResolver"

interface RichTextProps extends React.ComponentPropsWithoutRef<"div"> {
	field: NonNullable<PrismicRichTextProps["field"]>
	componentOverrides?: PrismicRichTextProps["components"]
}

export const RichText = ({
	field,
	componentOverrides,
	...props
}: RichTextProps) => {
	return (
		<div {...props}>
			<PrismicRichText
				field={field}
				components={{
					heading1: (props) => <h1>{props.children}</h1>,
					heading2: (props) => <h2>{props.children}</h2>,
					heading3: (props) => <h3>{props.children}</h3>,
					heading4: (props) => <h4>{props.children}</h4>,
					heading5: (props) => <h5>{props.children}</h5>,
					heading6: (props) => <h6>{props.children}</h6>,
					paragraph: (props) => <p>{props.children}</p>,
					strong: (props) => (
						<strong className="font-bold">{props.children}</strong>
					),
					hyperlink: (props) => (
						<PrismicLink
							href={props.node.data.url ?? linkResolver(props.node.data as any)}
							{...props}
						>
							{props.children}
						</PrismicLink>
					),
					...componentOverrides,
				}}
			/>
		</div>
	)
}
