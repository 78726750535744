import * as React from "react"
import { Link } from "./Link"
import clsx from 'clsx'
import * as styles from './Button.module.css'

export type PlainButtonProps = React.ComponentPropsWithoutRef<"button">
export type PlainAnchorProps = React.ComponentPropsWithoutRef<"a">
export type PolymorphicButtonProps = PlainButtonProps | PlainAnchorProps

interface StaticButtonProps {
	variant?: boolean
	className?: string
	state?: object
}

export type ButtonProps = PolymorphicButtonProps & StaticButtonProps

export const Button = React.forwardRef<
	HTMLButtonElement | HTMLAnchorElement,
	ButtonProps
>(({ children, variant, className, state, ...props }, ref) => {
	if ("href" in props) {
		return (
			<Link
				ref={ref as React.ForwardedRef<HTMLAnchorElement>}
				className={clsx(
					styles.button,
					variant && styles.buttonVariant,
					className
				)}
				state={state}
				{...(props as PlainAnchorProps)}
			>
				{children}
			</Link>
		)
	}

	return (
		<button
			ref={ref as React.ForwardedRef<HTMLButtonElement>}
			className={clsx(
				styles.button,
				variant && styles.buttonVariant,
				className
			)}
			{...(props as PlainButtonProps)}
		>
			{children}
		</button>
	)
})

Button.displayName = "Button"
