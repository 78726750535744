import clsx from "clsx"
import * as styles from "./SliceContainer.module.css"

type BackgroundColor =
	| "red"
	| "blue"
	| "teal"
	| "purple"
	| "yellow-green"
	| "white"

export interface SliceContainerProps
	extends React.ComponentPropsWithoutRef<"section"> {
	backgroundColor: BackgroundColor
	withCollapse?: boolean
	className?: string
}

export const SliceContainer = ({
	backgroundColor,
	withCollapse = true,
	children,
	className,
	...props
}: SliceContainerProps) => {
	return (
		<section
			data-collapse={withCollapse}
			data-bg={backgroundColor}
			className={clsx(styles.container, className)}
			{...props}
		>
			{children}
		</section>
	)
}
