/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	MapToComponents,
	type TCtxWithContext,
	type MapToComponentsProps,
} from "@walltowall/map-to-components"
import { sliceMap, mapDataToProps } from "../slices/PageDataBody"

interface Slice {
	__typename?: string
}

export type MapDataToPropsCtx<
	TSlice extends Slice = any,
	TContext = any,
> = TCtxWithContext<string, typeof sliceMap, TSlice, never, TContext>

function formatTypename(typename?: string) {
	return typename?.replace("Prismic", "") ?? ""
}

function getType(slice: Slice) {
	return formatTypename(slice.__typename)
}

function getKey(slice: Slice, idx: number) {
	return formatTypename(slice.__typename) + idx.toString()
}

function defaultMapDataToProps({ data }: MapDataToPropsCtx) {
	return data
}

interface Props {
	slices: MapToComponentsProps["list"]
}

export const MapSlicesToComponents = ({ slices }: Props) => {
	return (
		<MapToComponents
			map={sliceMap}
			mapDataToProps={mapDataToProps}
			getKey={getKey}
			getType={getType}
			list={slices}
			defaultMapDataToProps={defaultMapDataToProps}
			default={({ type }) => {
				if (process.env.NODE_ENV !== "development") return null

				return (
					<div
						style={{
							fontWeight: "bold",
							textAlign: "center",
							background: "#fecaca",
							color: "#7f1d1d",
							padding: "2rem 5rem",
						}}
					>
						Unable to find mapping for: {type}
					</div>
				)
			}}
		/>
	)
}
