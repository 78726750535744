import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import playUrl from "../assets/icon-play-video.svg"
import { useEffect, useId, useRef, useState } from "react"
import clsx from "clsx"
import Vimeo from "@vimeo/player"

import type { PageDataBodyVideoFragment } from "../graphql.gen"

import * as styles from "./PageDataBodyVideo.module.css"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyVideo = ({ video, image, imageAlt, title }: Props) => {
	const [videoElem, setVideoElem] = useState(null)
	const [hideCover, setHideCover] = useState(false)
	const videoRef = useRef(null)
	const id = useId()

	useEffect(() => {
		const YTsrc = document.querySelector('script[src*="youtube.com"]')

		if (!YTsrc) {
			const tag = document.createElement("script")
			tag.src = "https://www.youtube.com/iframe_api"
			const firstScriptTag = document.getElementsByTagName("script")[0]
			firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag)
		}

		window.onYouTubeIframeAPIReady = () => {
			const iframe = videoRef?.current?.querySelector("iframe")
			if (iframe) {
				iframe.id = id

				if (iframe.src.includes("youtube")) {
					iframe.src = `${iframe.src}&enablejsapi=1`
				}

				setVideoElem(iframe)
			}
		}
	}, [])

	const playVideo = () => {
		setHideCover(true)

		if (videoElem?.src?.includes("youtube")) {
			new YT.Player(videoElem.id, {
				events: {
					onReady: (event) => event.target?.playVideo(),
				},
			})
		} else if (videoElem?.src?.includes("vimeo")) {
			const vimeoVideo = new Vimeo(videoElem.id)
			vimeoVideo?.play()
		}
	}

	return (
		<section className={styles.videoSlice}>
			<div className={styles.video}>
				{video && (
					<div
						ref={videoRef}
						dangerouslySetInnerHTML={{
							__html: video,
						}}
					/>
				)}

				{image && (
					<>
						<GatsbyImage
							image={image}
							alt={imageAlt || ""}
							className={clsx(styles.cover, hideCover && styles.hide)}
						/>

						<button
							className={clsx(styles.play, hideCover && styles.hide)}
							aria-label="Play Video"
							onClick={playVideo}
						>
							<img src={playUrl} alt="Play Video" aria-hidden="true" />
						</button>
					</>
				)}
			</div>

			<div className={styles.title}>{title && <h3>{title}</h3>}</div>
		</section>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyVideoFragment>) {
	return {
		video: data.primary.video?.html,
		image: data.primary.cover_image?.gatsbyImageData as IGatsbyImageData,
		imageAlt: data.primary.cover_image?.alt,
		title: data.primary.video_title?.text,
	}
}

export const fragment = graphql`
	fragment PageDataBodyVideo on PrismicPageDataBodyVideo {
		primary {
			video {
				html
			}
			cover_image {
				gatsbyImageData(width: 900, layout: FULL_WIDTH)
				alt
			}
			video_title {
				text
			}
		}
	}

	fragment NewsPostDataBodyVideo on PrismicNewsPostDataBodyVideo {
		primary {
			video {
				html
			}
			cover_image {
				gatsbyImageData(width: 900, layout: FULL_WIDTH)
				alt
			}
			video_title {
				text
			}
		}
	}

	fragment StoryDataBodyVideo on PrismicStoryDataBodyVideo {
		primary {
			video {
				html
			}
			cover_image {
				gatsbyImageData(width: 900, layout: FULL_WIDTH)
				alt
			}
			video_title {
				text
			}
		}
	}
`

export default PageDataBodyVideo
