import * as React from "react"
import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import clsx from "clsx"
import { isFilled } from "@prismicio/helpers"

import { RichText } from "../components/RichText"

import type { PageDataBodyFormFragment } from "../graphql.gen"
import { Button } from "../components/Button"
import { useLoadGoogleRecaptcha } from "../hooks/useLoadGoogleRecaptcha"
import { RECAPTCHA_SITE_KEY } from "../constants"

import * as styles from "./PageDataBodyForm.module.css"

interface FieldProps {
	label?: string
	required?: boolean
}

interface FieldShellProps
	extends Pick<FieldProps, "label">,
		Pick<TextFieldProps, "fullWidth"> {
	children: React.ReactNode
}

const FieldShell = ({
	label,
	children,
	fullWidth = false,
}: FieldShellProps) => {
	return (
		<label className={clsx(styles.field, fullWidth && styles.fullWidth)}>
			{label && <span className={styles.label}>{label}</span>}

			{children}
		</label>
	)
}

interface TextFieldProps extends FieldProps {
	fullWidth?: boolean
}

const TextField = ({ label, required, fullWidth }: TextFieldProps) => {
	return (
		<FieldShell label={label} fullWidth={fullWidth}>
			<input
				type="text"
				required={required}
				className={styles.text}
				name={label}
			/>
		</FieldShell>
	)
}

const TextareaField = ({ label, required }: FieldProps) => {
	return (
		<FieldShell fullWidth label={label}>
			<textarea required={required} className={styles.textarea} name={label} />
		</FieldShell>
	)
}

type FormProps = React.ComponentPropsWithoutRef<"form"> &
	Pick<Props, "fields" | "successHeading" | "successText" | "formUid">

type FormState = "submitting" | "entry" | "submitted" | "error"

const Form = ({
	formUid,
	fields,
	successHeading,
	successText,
	...props
}: FormProps) => {
	const [state, setState] = React.useState<FormState>("entry")
	const shouldShowFormElement = ["entry", "submitting", "error"].includes(state)

	async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()

		setState("submitting")

		try {
			const token = await grecaptcha.execute(RECAPTCHA_SITE_KEY, {
				action: "submit",
			})

			const formData = {
				formUid,
				token,
				// @ts-expect-error FormData instances are iterable objects
				...Object.fromEntries(new FormData(e.target as HTMLFormElement)),
			}

			const res = await fetch("/api/form", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(formData),
			})
			if (res.status >= 300) {
				return setState("error")
			}

			const data = await res.json()
			if (data.success) return setState("submitted")
		} catch (err) {
			console.warn(err)
			setState("error")
		}
	}

	return (
		<>
			{!shouldShowFormElement && (
				<div className={styles.success}>
					{successHeading && <p>{successHeading}</p>}

					{successText && <p>{successText}</p>}
				</div>
			)}

			{shouldShowFormElement && (
				<form onSubmit={handleSubmit} className={clsx(styles.form)} {...props}>
					{fields?.map((field) => {
						switch (field.__typename) {
							case "PrismicFormDataBodyTextField":
								return (
									<TextField
										key={field.primary.label?.text}
										label={field.primary.label?.text}
										required={field.primary.required}
										fullWidth={field.primary.full_width}
									/>
								)

							case "PrismicFormDataBodyTextareaField":
								return (
									<TextareaField
										key={field.primary.label?.text}
										label={field.primary.label?.text}
										required={field.primary.required}
									/>
								)

							default:
								return null
						}
					})}

					{state === "error" && (
						<p className={styles.error}>
							Sorry something went wrong, please try again.
						</p>
					)}

					<Button
						className={clsx(
							styles.submit,
							state === "error" && styles.submitError,
						)}
						disabled={state === "submitting"}
					>
						Submit
					</Button>
				</form>
			)}
		</>
	)
}

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyForm = ({
	text,
	textLinks,
	formUid,
	fields,
	successHeading,
	successText,
}: Props) => {
	useLoadGoogleRecaptcha()
	const hasColumns = isFilled.richText(text) || textLinks.length > 0

	return (
		<div>
			<div className={styles.container}>
				{hasColumns && (
					<div className={styles.copy}>
						{isFilled.richText(text) && (
							<RichText
								field={text}
								componentOverrides={{
									heading3: (props) => (
										<h3 className={styles.heading3}>{props.children}</h3>
									),

									paragraph: (props) => (
										<p className={styles.paragraph}>{props.children}</p>
									),
								}}
							/>
						)}
					</div>
				)}

				<Form
					formUid={formUid}
					fields={fields}
					successHeading={successHeading}
					successText={successText}
				/>
			</div>
		</div>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyFormFragment>) {
	const primary = data.primary
	const formDoc = data.primary.form?.document
	if (formDoc && formDoc.__typename !== "PrismicForm") {
		throw new Error("Did not receive Form document when queried for!")
	}

	return {
		text: primary.text?.richText,
		formUid: formDoc?.uid,
		fields: formDoc?.data.body,
		successHeading: formDoc?.data.success_heading?.text,
		successText: formDoc?.data.success_text?.text,
	}
}

export const fragment = graphql`
	fragment TextareaField on PrismicFormDataBodyTextareaField {
		primary {
			label {
				text
			}
			required
		}
	}

	fragment TextField on PrismicFormDataBodyTextField {
		primary {
			label {
				text
			}
			required
			full_width
		}
	}

	fragment PageDataBodyForm on PrismicPageDataBodyForm {
		primary {
			form {
				document {
					__typename
					... on PrismicForm {
						_previewable
						uid
						data {
							body {
								__typename
								...TextField
								...TextareaField
							}
							success_heading {
								text
							}
							success_text {
								text
							}
						}
					}
				}
			}
			text {
				richText
			}
		}
	}
`

export default PageDataBodyForm
