import clsx from "clsx"
import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { isFilled } from "@prismicio/helpers"
import { motion } from "framer-motion"

import { RichText } from "../components/RichText"
import { useRotateOnScroll } from "../hooks/useRotateOnScroll"
import { LinkWithArrow } from "../components/LinkWithArrow"
import { SliceContainer } from "../components/SliceContainer"

import type { PageDataBodyTextWithBackgroundFragment } from "../graphql.gen"

import wheelRedUrl from "../assets/gears-hope-red.svg"
import wheelnavyUrl from "../assets/gears-crush-navy.svg"
import wheelPurpleUrl from "../assets/gears-drive.svg"

import * as typography from "../styles/typography.module.css"
import * as styles from "./PageDataBodyTextWithBackground.module.css"
import { ContentContainer } from "../components/ContentContainer"

type Props = ReturnType<typeof mapDataToProps>
type Color = "red" | "blue" | "white"

function wheel(gear) {
	const rotate = useRotateOnScroll(5)
	let wheelUrl = wheelRedUrl

	if (gear == "Gear 2") {
		wheelUrl = wheelnavyUrl
	} else if (gear == "Gear 3") {
		wheelUrl = wheelPurpleUrl
	}

	return (
		<motion.img
			src={wheelUrl}
			className={styles.wheel}
			loading="lazy"
			decoding="async"
			style={{ rotate }}
		/>
	)
}

export const PageDataBodyTextWithBackground = ({
	richText,
	link,
	linkText,
	color,
	align_content,
	gear_placement,
	gear_select,
}: Props) => {
	return (
		<SliceContainer
			className={styles.container}
			backgroundColor={color}
			data-color-theme={color}
			data-background={color}
			data-content-center={align_content}
			data-gear-placement={gear_placement}
		>
			<ContentContainer className={styles.wheelContainer}>
				{wheel(gear_select)}
			</ContentContainer>

			<ContentContainer className={styles.subContainer} variant="narrow">
				{isFilled.richText(richText) && (
					<RichText
						field={richText}
						className={styles.richText}
						componentOverrides={{
							heading2: (props) => (
								<h2 className={clsx(typography.heading2, styles.heading)}>
									{props.children}
								</h2>
							),
							heading4: (props) => (
								<h4 className={clsx(typography.heading5, styles.subheading)}>
									{props.children}
								</h4>
							),
							paragraph: (props) => (
								<p className={clsx(typography.paragraph1, styles.paragraph)}>
									{props.children}
								</p>
							),
							list: (props) => (
								<ul className={styles.list}>{props.children}</ul>
							),
							listItem: (props) => (
								<li className={typography.listItem} data-color="yellow">
									{props.children}
								</li>
							),
							oList: (props) => (
								<ol className={styles.orderedList}>{props.children}</ol>
							),
							oListItem: (props) => (
								<li className={typography.orderedListItem}>{props.children}</li>
							),
						}}
					/>
				)}

				{linkText && (
					<LinkWithArrow href={link} className={styles.link}>
						{linkText}
					</LinkWithArrow>
				)}
			</ContentContainer>
		</SliceContainer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyTextWithBackgroundFragment>) {
	return {
		richText: data.primary.rich_text?.richText,
		link: data.primary.link?.url,
		linkText: data.primary.link_text,
		color: data.primary.color_theme?.toLowerCase() as Color,
		align_content: data.primary.align_content,
		gear_placement: data.primary.gear_placement,
		gear_select: data.primary.gear_select,
	}
}

export const fragment = graphql`
	fragment PageDataBodyTextWithBackground on PrismicPageDataBodyTextWithBackground {
		primary {
			rich_text {
				richText
			}
			link {
				url
			}
			link_text
			color_theme
			align_content
			gear_placement
			gear_select
		}
	}
`

export default PageDataBodyTextWithBackground
