import { isFilled } from "@prismicio/helpers"
import clsx from "clsx"

import { RichText } from "./RichText"
import { VisuallyHidden } from "./VisuallyHidden"
import { RemoteImage } from "./RemoteImage"

import * as typography from "../styles/typography.module.css"
import * as styles from "./CtaCard.module.css"
import { Link } from './Link'
import { LinkWithArrow } from "./LinkWithArrow"

interface Props {
	imgUrl?: string
	imgAlt?: string
	imgDimensions?: { width: number; height: number }
	title?: string
	copy: any
	link?: string
	linkText?: string
}

// TODO: This + <IllustrationCard /> should really just be one single component
// with different variants.
export const CtaCard = ({
	imgUrl,
	imgAlt,
	imgDimensions,
	title,
	copy,
	link,
	linkText,
}: Props) => {
	const Component = link ? Link : "div"

	return (
		<Component href={link} className={clsx(
			styles.container,
			link && styles.containerHover
		)}>
			{imgUrl && (
				<RemoteImage
					src={imgUrl}
					alt={imgAlt ?? ""}
					className={styles.image}
					width={imgDimensions?.width}
					height={imgDimensions?.height}
				/>
			)}

			{title && (
				<h3 className={clsx(typography.heading3, styles.header)}>{title}</h3>
			)}

			{isFilled.richText(copy) && (
				<RichText
					field={copy}
					className={clsx(typography.paragraph2, styles.copy)}
				/>
			)}

			{link && linkText && (
				<div className={styles.linkContainer}>
					<LinkWithArrow className={styles.link}>
						{linkText}
						{title && <VisuallyHidden>Learn more about {title}</VisuallyHidden>}
					</LinkWithArrow>
				</div>
			)}
		</Component>
	)
}
