import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { Link } from "../components/Link"

import type { PageDataBodyRideOptionHeroFragment } from "../graphql.gen"

import * as styles from "./PageDataBodyRideOptionHero.module.css"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyRideOptionHero = ({
	heading,
	kicker,
	background,
	backgroundAlt,
	parentURL,
	parentTitle,
}: Props) => {
	return (
		<section className={styles.rideOptionHero}>
			{parentURL && (
				<Link href={parentURL} className={styles.breadcrumb}>
					{parentTitle}
				</Link>
			)}

			{heading && (
				<h1>
					{kicker && <span className={styles.kicker}>{kicker}</span>}
					{heading}
				</h1>
			)}

			{background && (
				<img
					className={styles.illustration}
					src={background}
					role="presentation"
					alt={backgroundAlt || ""}
				/>
			)}
		</section>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyRideOptionHeroFragment>) {
	return {
		heading: data.primary.heading?.text,
		kicker: data.primary.kicker_text,
		background: data.primary.background_illustration?.url,
		backgroundAlt: data.primary.background_illustration?.alt,
		parentURL: data.primary.parent_page?.url,
		parentTitle: data.primary.parent_page?.document?.data?.title?.text,
	}
}

export const fragment = graphql`
	fragment PageDataBodyRideOptionHero on PrismicPageDataBodyRideOptionHero {
		primary {
			kicker_text
			heading {
				text
			}
			background_illustration {
				url
				alt
			}
			parent_page {
				url
				document {
					... on PrismicPage {
						id
						data {
							title {
								text
							}
						}
					}
				}
			}
		}
	}
`

export default PageDataBodyRideOptionHero
