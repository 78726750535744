import { siteSearch } from "../lib/siteSearch"
import { useRef, useState } from "react"
import { SearchIcon } from './SearchIcon'
import clsx from 'clsx'
import * as styles from './SearchExpand.module.css'

export const SearchExpand = () => {
  const [expanded, setExpanded] = useState(false)
  const inputRef = useRef(null)
  const formRef = useRef<HTMLFormElement>(null)

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		const target = e.target as typeof e.target & {
			query: HTMLInputElement
		}

		siteSearch({ query: target.query.value, skip: 0 })
    setExpanded(false)
	}

  const revealForm = () => {
    setExpanded(true)
    inputRef.current?.focus()
  }

  return (
    <div className={styles.headerSearch}>
      <button
        className={styles.searchButton}
        onClick={revealForm}
        aria-label="Toggle Search Field"
      >
        <SearchIcon />
      </button>

      <form
        onSubmit={handleSubmit}
        className={clsx(
          styles.headerSearchForm,
          expanded ? styles.headerSearchFormExpanded : ''
        )}
        ref={formRef}
      >
        <label>
          <span>Search</span>
          <input
            ref={inputRef}
            name="query"
            type="text"
            placeholder="Type something"
            onBlur={() => setExpanded(false)}
          />
        </label>

        <button title="Submit" type="submit">
          <SearchIcon />
        </button>
      </form>
    </div>
  )
}