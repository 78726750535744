import { useEffect, useRef, useState } from "react"
import { useLocation } from "@gatsbyjs/reach-router"
import { Footer } from "./Footer"
import * as styles from "./Layout.module.css"
import { ClipPathSVG } from "./WheelClipSVG"

interface LayoutProps {
	children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
	const location = useLocation()
	const overlayRef = useRef(null)
	const [childElements, setChildElements] = useState([])
	const [background, setBackground] = useState("")

	useEffect(() => {
		window.addEventListener("scroll", transitionBackgrounds)

		return () => {
			window.removeEventListener("scroll", transitionBackgrounds)
		}
	}, [location])

	useEffect(() => {
		childElements?.forEach((child) => (child.style.background = background))
	}, [background, childElements])

	const transitionBackgrounds = () => {
		if (location.pathname === "/") {
			const homeSlices = Array.from(
				overlayRef?.current?.querySelectorAll("*[data-background]"),
			)
			setChildElements(homeSlices)

			homeSlices.forEach((child) => {
				const bounding = child?.getBoundingClientRect()

				const colors = {
					red: "#f55058",
					blue: "#021d45",
				}

				if (
					bounding.top <= window.innerHeight / 2 &&
					bounding.bottom >= window.innerHeight / 2
				) {
					setBackground(colors[child.dataset.background])
				}
			})
		}
	}

	return (
		<>
			<main>
				<ClipPathSVG />
				<div className={styles.mainOverlay} ref={overlayRef}>
					{children}
				</div>
			</main>
			<Footer />
		</>
	)
}
