import { graphql } from "gatsby"
import { PrismicLink } from "@prismicio/react"
import { isFilled } from "@prismicio/helpers"
import clsx from "clsx"
import { useLocation } from "@gatsbyjs/reach-router"

import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { RichText } from "../components/RichText"
import { linkResolver } from "../prismic/linkResolver"
import { SliceContainer } from "../components/SliceContainer"
import { ContentContainer } from "../components/ContentContainer"

import type { PageDataBodyRichTextFragment } from "../graphql.gen"

import * as typography from "../styles/typography.module.css"
import * as styles from "./PageDataBodyRichText.module.css"

type Props = ReturnType<typeof mapDataToProps>

// Matches when a string contains "/stories/SOME_PATH", but not
// /stories/
const STORIES_REGEX = /\/stories\/\b/

export const PageDataBodyRichText = ({ text }: Props) => {
	const location = useLocation()
	const onStoriesPage = Boolean(location.pathname.match(STORIES_REGEX))

	return (
		<SliceContainer backgroundColor="white">
			<ContentContainer
				className={styles.richText}
				variant="narrow"
				centered={!onStoriesPage}
			>
				{isFilled.richText(text) && (
					<RichText
						field={text}
						componentOverrides={{
							paragraph: (props) => (
								<p className={clsx(typography.paragraph1, styles.paragraph)}>
									{props.children}
								</p>
							),
							heading2: (props) => (
								<h2 className={clsx(typography.heading2, styles.heading2)}>
									{props.children}
								</h2>
							),
							heading3: (props) => (
								<h3 className={clsx(typography.heading3, styles.heading3)}>
									{props.children}
								</h3>
							),
							heading4: (props) => (
								<h4 className={clsx(typography.heading4, styles.heading4)}>
									{props.children}
								</h4>
							),
							heading5: (props) => (
								<h5 className={clsx(typography.heading5, styles.heading5)}>
									{props.children}
								</h5>
							),
							hyperlink: (props) => (
								<PrismicLink
									className={styles.link}
									href={
										props.node.data.url ?? linkResolver(props.node.data as any)
									}
									{...props}
								>
									{props.children}
								</PrismicLink>
							),
							list: (props) => (
								<ul className={clsx(typography.list, styles.list)}>
									{props.children}
								</ul>
							),
							listItem: (props) => (
								<li className={typography.listItem} data-color="yellow">
									{props.children}
								</li>
							),
							oList: (props) => (
								<ol
									className={clsx(typography.orderedList, styles.orderedList)}
								>
									{props.children}
								</ol>
							),
							oListItem: (props) => (
								<li className={typography.orderedListItem}>{props.children}</li>
							),
						}}
					/>
				)}
			</ContentContainer>
		</SliceContainer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyRichTextFragment>) {
	return {
		text: data.primary.text?.richText,
	}
}

export const fragment = graphql`
	fragment PageDataBodyRichText on PrismicPageDataBodyRichText {
		primary {
			text {
				richText
			}
		}
	}

	fragment NewsPostDataBodyRichText on PrismicNewsPostDataBodyRichText {
		primary {
			text {
				richText
			}
		}
	}

	fragment StoryDataBodyRichText on PrismicStoryDataBodyRichText {
		primary {
			text {
				richText
			}
		}
	}

	fragment StoriesDataBodyRichText on PrismicStoriesDataBodyRichText {
		primary {
			text {
				richText
			}
		}
	}
`

export default PageDataBodyRichText
