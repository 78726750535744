import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { RichText } from "../components/RichText"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import clsx from "clsx"
import { useCallback, useEffect, useRef, useState } from "react"
import useEmblaCarousel from "embla-carousel-react"
import { PrevButton } from "../components/PrevButton"
import { NextButton } from "../components/NextButton"
import { TiresFull } from "../components/TiresFull"

import type { PageDataBodyRouteFeaturesFragment } from "../graphql.gen"

import * as emblaStyles from "../styles/EmblaCarousel.module.css"
import * as styles from "./PageDataBodyRouteFeatures.module.css"

import tires1URL from "../assets/tires-one.svg"
import tires2URL from "../assets/tires-two.svg"
import tires3URL from "../assets/tires-three.svg"
import tires4URL from "../assets/tires-four.svg"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyRouteFeatures = ({
	heading,
	copy,
	feature1Number,
	feature1Heading,
	feature1Image,
	feature1Alt,
	feature2Number,
	feature2Heading,
	feature2Image,
	feature2Alt,
	feature3Number,
	feature3Heading,
	feature3Image,
	feature3Alt,
	landmarks,
}: Props) => {
	const [sliderShown, setSliderShown] = useState(false)
	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
	const [viewAllShow, setViewAllShow] = useState(false)
	const featuresRef = useRef<HTMLDivElement>(null)
	const tiresRef = useRef<SVGSVGElement>(null)
	const viewAllRef = useRef(null)
	const landmarkContainerRef = useRef<HTMLDivElement>(null)

	{
		/* TODO: dave */
	}
	const [emblaRef, embla] = useEmblaCarousel({
		skipSnaps: true,
		containScroll: "trimSnaps",
		slidesToScroll: 1,
	})

	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

	const onSelect = useCallback(() => {
		if (!embla) return
		setPrevBtnEnabled(embla.canScrollPrev())
		setNextBtnEnabled(embla.canScrollNext())
	}, [embla])

	useEffect(() => {
		if (!embla) return
		onSelect()
		embla.on("select", onSelect)
	}, [embla, onSelect])

	useEffect(() => {
		window.addEventListener("scroll", getScrollDistance)
		getScrollDistance()

		return () => {
			window.removeEventListener("scroll", getScrollDistance)
		}
	}, [])

	const setViewAllPosition = (event) => {
		if (viewAllRef.current) {
			if (!viewAllShow && !sliderShown) setViewAllShow(true)

			const x = event.clientX
			const y = event.clientY

			viewAllRef.current.style.left = `${x}px`
			viewAllRef.current.style.top = `${y}px`
		}
	}

	const removeViewAllPosition = () => {
		setViewAllShow(false)
	}

	const getScrollDistance = () => {
		const landmarkContainer = landmarkContainerRef.current
		if (!landmarkContainer) return

		if (tiresRef.current && featuresRef.current) {
			const landmarks = Array.from(
				document.querySelectorAll<HTMLElement>(".landmarkImg"),
			)
			const landmarkPreviewPos = landmarkContainer.getBoundingClientRect()

			const windowHeight = window.innerHeight
			const featuresHeight = featuresRef?.current?.offsetHeight
			const bounding = tiresRef?.current?.getBoundingClientRect()
			const paths = Array.from(
				tiresRef.current?.querySelectorAll<SVGGElement>("g"),
			)

			if (
				landmarkPreviewPos.top < windowHeight * 0.66 &&
				landmarkPreviewPos.top !== 0
			) {
				landmarks.forEach((item) => {
					item.style.display = "block"
				})
			}

			if (bounding.top < windowHeight * 0.66) {
				paths.forEach((path) => (path.style.opacity = "0"))

				let percent = 0
				const distance = -(bounding.top - windowHeight * 0.66) / featuresHeight

				if (distance > 1) {
					percent = 1
				} else {
					percent = distance
				}

				const childCount = Math.floor(paths.length * percent)
				paths
					.reverse()
					.splice(0, childCount)
					.forEach((path) => (path.style.opacity = "0.47"))
			} else {
				paths.forEach((path) => (path.style.opacity = "0"))
			}
		}
	}

	return (
		<section className={styles.routeFeatures}>
			<div className={styles.routeHeading}>
				{heading && <h2>{heading}</h2>}
				<RichText field={copy} />
			</div>

			<div className={styles.featureList} ref={featuresRef}>
				<TiresFull ref={tiresRef} aria-hidden="true" />

				<div className={clsx(styles.feature, styles.feature1)}>
					<img src={tires1URL} aria-hidden="true" role="presentation" />

					{feature1Image && (
						<div className={styles.featureImage}>
							<GatsbyImage
								image={feature1Image}
								alt={feature1Alt || ""}
								objectFit="cover"
								objectPosition="center"
							/>
						</div>
					)}

					{feature1Heading && (
						<h3>
							{feature1Number && (
								<span
									className={styles.largeNumber}
								>{`${feature1Number} `}</span>
							)}
							{feature1Heading && <span>{feature1Heading}</span>}
						</h3>
					)}
				</div>

				<div className={clsx(styles.feature, styles.feature2)}>
					<img src={tires2URL} aria-hidden="true" role="presentation" />

					{feature2Image && (
						<div className={styles.featureImage}>
							<GatsbyImage
								image={feature2Image}
								alt={feature2Alt || ""}
								objectFit="cover"
								objectPosition="center"
							/>
						</div>
					)}

					{feature2Heading && (
						<h3>
							{feature2Number && (
								<span
									className={styles.largeNumber}
								>{`${feature2Number} `}</span>
							)}
							{feature2Heading && <span>{feature2Heading}</span>}
						</h3>
					)}
				</div>

				<div className={clsx(styles.feature, styles.feature3)}>
					<img src={tires3URL} aria-hidden="true" role="presentation" />

					{feature3Image && (
						<div className={styles.featureImage}>
							<GatsbyImage
								image={feature3Image}
								alt={feature3Alt || ""}
								objectFit="cover"
								objectPosition="center"
							/>
						</div>
					)}

					{feature3Heading && (
						<h3>
							{feature3Number && (
								<span
									className={styles.largeNumber}
								>{`${feature3Number} `}</span>
							)}
							{feature3Heading && <span>{feature3Heading}</span>}
						</h3>
					)}
				</div>

				<div className={styles.landmarkContainer} ref={landmarkContainerRef}>
					<img src={tires4URL} aria-hidden="true" role="presentation" />

					<div
						className={clsx(
							styles.landmarkPreview,
							!sliderShown && styles.show,
						)}
						onMouseMove={setViewAllPosition}
						onMouseOut={removeViewAllPosition}
						onClick={() => {
							setSliderShown(true)
							setViewAllShow(false)
						}}
					>
						<div className={styles.landmarkCards} aria-hidden="true">
							{landmarks.splice(0, 3).map((item) => (
								<div key={item.title}>
									{item.image && (
										<GatsbyImage
											image={item.image}
											alt={item.alt || ""}
											objectFit="cover"
											objectPosition="center"
											className="landmarkImg"
										/>
									)}
								</div>
							))}
						</div>

						{landmarks.length > 0 && (
							<>
								<h3>Landmarks</h3>

								<button
									className={clsx(
										styles.viewAll,
										styles.mouseFollow,
										viewAllShow && styles.mouseFollowShow,
									)}
									aria-hidden="true"
									ref={viewAllRef}
								>
									View All
								</button>
							</>
						)}
					</div>
					{/* TODO: dave */}
					<div
						className={clsx(styles.landmarkSlider, sliderShown && styles.show)}
					>
						<div className={emblaStyles.embla} ref={emblaRef}>
							<div className={emblaStyles.emblaContainer}>
								{landmarks.map((item, index) => (
									<div
										key={index}
										className={clsx(
											emblaStyles.emblaSlide,
											styles.landmarkSlide,
										)}
									>
										<div>
											{item.image && (
												<GatsbyImage
													image={item.image}
													alt={item.alt || ""}
													objectFit="cover"
													objectPosition="center"
												/>
											)}
										</div>

										{item.title && <h4>{item.title}</h4>}
										{item.description && <RichText field={item.description} />}
									</div>
								))}
							</div>
						</div>

						<button
							className={clsx(styles.viewAll, styles.close)}
							onClick={() => setSliderShown(false)}
							aria-hidden="true"
						>
							close
						</button>

						<PrevButton
							enabled={prevBtnEnabled}
							onClick={scrollPrev}
							className={styles.prev}
						/>
						<NextButton
							enabled={nextBtnEnabled}
							onClick={scrollNext}
							className={styles.next}
						/>
					</div>
				</div>
			</div>
		</section>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyRouteFeaturesFragment>) {
	return {
		heading: data.primary.heading?.text,
		copy: data.primary.rich_text?.richText,
		feature1Number: data.primary?.feature_number_1,
		feature1Heading: data.primary.feature_heading_1?.text,
		feature1Image: data.primary.feature_image_1
			?.gatsbyImageData as IGatsbyImageData,
		feature1Alt: data.primary.feature_image_1?.alt,
		feature2Number: data.primary?.feature_number_2,
		feature2Heading: data.primary.feature_heading_2?.text,
		feature2Image: data.primary.feature_image_2
			?.gatsbyImageData as IGatsbyImageData,
		feature2Alt: data.primary.feature_image_2?.alt,
		feature3Number: data.primary?.feature_number_3,
		feature3Heading: data.primary.feature_heading_3?.text,
		feature3Image: data.primary.feature_image_3
			?.gatsbyImageData as IGatsbyImageData,
		feature3Alt: data.primary.feature_image_3?.alt,
		landmarks: data.items.map((item) => {
			return {
				image: item.landmark_image?.gatsbyImageData as IGatsbyImageData,
				alt: item.landmark_image?.alt,
				title: item.landmark_title?.text,
				description: item.landmark_description?.richText,
			}
		}),
	}
}

export const fragment = graphql`
	fragment PageDataBodyRouteFeatures on PrismicPageDataBodyRouteFeatures {
		primary {
			heading {
				text
			}
			rich_text {
				richText
			}
			feature_number_1
			feature_number_2
			feature_number_3
			feature_heading_1 {
				text
			}
			feature_heading_2 {
				text
			}
			feature_heading_3 {
				text
			}
			feature_image_1 {
				gatsbyImageData(width: 800, layout: FULL_WIDTH)
				alt
			}
			feature_image_2 {
				gatsbyImageData(width: 800, layout: FULL_WIDTH)
				alt
			}
			feature_image_3 {
				gatsbyImageData(width: 800, layout: FULL_WIDTH)
				alt
			}
		}

		items {
			landmark_image {
				gatsbyImageData(width: 800, layout: FULL_WIDTH)
				alt
			}
			landmark_title {
				text
			}
			landmark_description {
				richText
			}
		}
	}
`

export default PageDataBodyRouteFeatures
