import clsx from "clsx"
import { Link, LinkProps } from "./Link"

import * as styles from "./LinkWithArrow.module.css"

const Arrow = (props: React.ComponentPropsWithoutRef<"svg">) => {
	return (
		<svg viewBox="0 0 28 22" {...props}>
			<g fill="currentColor" fillRule="evenodd">
				<path d="M.8178 9.56207H23.148v2.87586H.8178z" />
				<path d="m17.11071.90439 10.04808 10.04808-2.03354 2.03354-.03764-.03701-8.14607 8.14661-2.03354-2.03354 8.14561-8.14707-7.97644-7.97707z" />
			</g>
		</svg>
	)
}

interface LinkWithArrowProps extends LinkProps {
	textClassName?: string
	href?: string
	arrowClassName?: string
}

export const LinkWithArrow = ({
	textClassName,
	arrowClassName,
	children,
	className,
	...props
}: LinkWithArrowProps) => {
	return (
		<>
		  {props.href ? (
				<Link className={clsx(styles.link, className)} {...props}>
					<div className={clsx(styles.text, textClassName)}>{children}</div>
					<Arrow className={clsx(styles.arrow, arrowClassName)} />
				</Link>
			) : (
				<div className={clsx(styles.link, className)}>
					<div className={clsx(styles.text, textClassName)}>{children}</div>
					<Arrow className={clsx(styles.arrow, arrowClassName)} />
				</div>
			)}
		</>
	)
}
