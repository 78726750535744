import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { isFilled } from "@prismicio/helpers"
import { RichText } from "../components/RichText"
import clsx from "clsx"

import type { PageDataBodyWeRideHeroFragment } from "../graphql.gen"
import * as styles from "./PageDataBodyWeRideHero.module.css"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyWeRideHero = ({ copyList }: Props) => {
	return (
		<section className={styles.container}>
			<div className={styles.headerContainer}>
				<h1 className={styles.header}>We Ride</h1>
			</div>
			<div className={styles.textContainer}>
				{copyList.map((copy, index) => {
					return (
						isFilled.richText(copy.richText) && (
							<RichText
								key={index}
								field={copy.richText}
								componentOverrides={{
									paragraph: (props) => (
										<p className={styles.copyText}>{props.children}</p>
									),
									strong: (props) => (
										<span className={clsx(styles.copyText, styles.boldOverlay)}>
											{props.children}
										</span>
									),
								}}
							/>
						)
					)
				})}
			</div>
		</section>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyWeRideHeroFragment>) {
	return {
		copyList: data.items.map((item) => {
			return {
				richText: item.rich_text?.richText,
			}
		}),
	}
}

export const fragment = graphql`
	fragment PageDataBodyWeRideHero on PrismicPageDataBodyWeRideHero {
		items {
			rich_text {
				richText
			}
		}
	}
`

export default PageDataBodyWeRideHero
