import { graphql, type HeadProps, type PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { MapSlicesToComponents } from "../components/MapSlicesToComponents"
import { SEO } from "../components/SEO"
import { useSiteSettings } from "../hooks/useSiteSettings"
import { PostTitle } from "../components/PostTitle"
import { Header } from "../components/Header"

import { StoriesPageQuery } from "../graphql.gen"
import type { AllStoriesData } from "../slices/PageDataBodyAllStories"

export interface StoriesPageContext {
	limit: number
	skip: number
	totalPages: number
	currentPage: number
}

const StoriesPage = ({
	data,
	pageContext,
}: PageProps<StoriesPageQuery, StoriesPageContext>) => {
	const page = data.prismicStories
	const title = page?.data.title?.text
	const gridHeading = page?.data?.story_grid_heading?.text
	const gridCopy = page?.data?.story_grid_copy?.richText

	const posts = data.allPrismicStory.nodes

	const bodySlices = page?.data.body ?? []

	const allStoriesData: AllStoriesData = {
		__typename: "PrismicPageDataBodyAllStories",
		posts,
		indexProps: { heading: gridHeading, copy: gridCopy },
		totalPages: pageContext.totalPages,
		currentPage: pageContext.currentPage,
	}

	const slices = [...bodySlices, allStoriesData]

	return (
		<>
			<Header />
			<PostTitle title={title} />
			<MapSlicesToComponents slices={slices} />
		</>
	)
}

export const Head = ({ data }: HeadProps<StoriesPageQuery>) => {
	const settings = useSiteSettings()
	const page = data.prismicStories

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle="Stories"
			meta={{
				description: page?.data.meta_description?.text,
				title: page?.data.meta_title?.text,
			}}
			twitter={{
				username: settings.twitter.username,
				cardImageUrl:
					page?.data.twitter_card?.url ?? settings.twitter.cardImageUrl,
			}}
			openGraph={{
				cardImageUrl:
					page?.data.open_graph_image?.url ?? settings.openGraph.cardImageUrl,
			}}
		/>
	)
}

export default withPrismicPreview(StoriesPage)

export const query = graphql`
	query StoriesPage($limit: Int!, $skip: Int!) {
		allPrismicStory(
			limit: $limit
			skip: $skip
			sort: { fields: data___publish_date, order: DESC }
		) {
			nodes {
				_previewable
				uid
				url
				data {
					title {
						text
					}
					summary {
						richText
					}
					image {
						alt
						gatsbyImageData(
							width: 400
							breakpoints: [320, 400]
							layout: FULL_WIDTH
						)
					}
					publish_date
				}
			}
		}

		prismicStories(uid: { eq: "stories" }) {
			...StoriesData
		}
	}

	fragment StoriesData on PrismicStories {
		_previewable
		uid
		data {
			title {
				text
			}
			story_grid_heading {
				text
			}
			story_grid_copy {
				richText
			}
			meta_description {
				text
			}
			meta_title {
				text
			}
			open_graph_image {
				url(imgixParams: { width: 1200, height: 630, q: 75 })
			}
			twitter_card {
				url(imgixParams: { width: 800, height: 418, q: 75 })
			}

			body {
				__typename
				...StoriesDataBody
			}
		}
	}
`
