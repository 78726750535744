// extracted by mini-css-extract-plugin
export var container = "PageDataBodyTextWithBackground-module--container--+GrBv";
export var heading = "PageDataBodyTextWithBackground-module--heading--Ghz1q";
export var link = "PageDataBodyTextWithBackground-module--link--Bn+vB";
export var list = "PageDataBodyTextWithBackground-module--list--DTJ3Q";
export var orderedList = "PageDataBodyTextWithBackground-module--orderedList--QlrSc";
export var paragraph = "PageDataBodyTextWithBackground-module--paragraph--nrNnE";
export var richText = "PageDataBodyTextWithBackground-module--richText--vkOvK";
export var subContainer = "PageDataBodyTextWithBackground-module--subContainer--nr3dl";
export var subheading = "PageDataBodyTextWithBackground-module--subheading--6PhnG";
export var wheel = "PageDataBodyTextWithBackground-module--wheel---790u";
export var wheelContainer = "PageDataBodyTextWithBackground-module--wheelContainer--dD-dV";