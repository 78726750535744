import { graphql } from "gatsby"
import clsx from "clsx"
import { isFilled } from "@prismicio/helpers"

import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { SliceContainer } from "../components/SliceContainer"
import { RichText } from "../components/RichText"

import type { PageDataBodyTextHeadingFragment } from "../graphql.gen"

import * as typography from "../styles/typography.module.css"
import * as styles from "./PageDataBodyTextHeading.module.css"
import { ContentContainer } from "../components/ContentContainer"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyTextHeading = ({
	header,
	text,
	centerText,
}: Props) => {
	return (
		<SliceContainer backgroundColor="white">
			{centerText ? (
				<ContentContainer variant="narrow" className={styles.centerToggle}>
					{header && (
						<h2 className={clsx(typography.heading2, styles.heading)}>
							{header}
						</h2>
					)}

					{isFilled.richText(text) && (
						<RichText
							field={text}
							className={styles.richText}
							componentOverrides={{
								paragraph: (props) => (
									<p className={clsx(typography.paragraph1, styles.paragraph)}>
										{props.children}
									</p>
								),
							}}
						/>
					)}
				</ContentContainer>
			) : (
				<ContentContainer variant="narrow">
					{header && (
						<h2 className={clsx(typography.heading2, styles.heading)}>
							{header}
						</h2>
					)}

					{isFilled.richText(text) && (
						<RichText
							field={text}
							className={styles.richText}
							componentOverrides={{
								paragraph: (props) => (
									<p className={clsx(typography.paragraph1, styles.paragraph)}>
										{props.children}
									</p>
								),
							}}
						/>
					)}
				</ContentContainer>
			)}
		</SliceContainer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyTextHeadingFragment>) {
	return {
		header: data.primary.heading?.text,
		text: data.primary.rich_text?.richText,
		centerText: data.primary.center_text,
	}
}

export const fragment = graphql`
	fragment PageDataBodyTextHeading on PrismicPageDataBodyTextHeading {
		primary {
			heading {
				text
			}
			rich_text {
				richText
			}
			center_text
		}
	}
`

export default PageDataBodyTextHeading
