// extracted by mini-css-extract-plugin
export var button1 = "PageDataBodyRideOptionDetails-module--button1--HGyj8";
export var button2 = "PageDataBodyRideOptionDetails-module--button2--8HQkr";
export var button2Arrow = "PageDataBodyRideOptionDetails-module--button2Arrow--hjCuj";
export var buttons = "PageDataBodyRideOptionDetails-module--buttons--BbTeB";
export var detail = "PageDataBodyRideOptionDetails-module--detail--4Th9g";
export var detailHeading = "PageDataBodyRideOptionDetails-module--detailHeading--SetZa";
export var detailList = "PageDataBodyRideOptionDetails-module--detailList--Duzk9";
export var detailText = "PageDataBodyRideOptionDetails-module--detailText--vpvi-";
export var details = "PageDataBodyRideOptionDetails-module--details--iAJ8+";
export var heading = "PageDataBodyRideOptionDetails-module--heading--TmXbs";
export var rideOptionDetails = "PageDataBodyRideOptionDetails-module--rideOptionDetails--YrRaC";
export var rideOptionImage = "PageDataBodyRideOptionDetails-module--rideOptionImage--kKQ3m";