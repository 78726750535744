import * as Collapsible from "@radix-ui/react-collapsible"
import { graphql } from "gatsby"
import { isFilled } from "@prismicio/helpers"

import { RichText } from "../components/RichText"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { LinkWithArrow } from "../components/LinkWithArrow"
import { SliceContainer } from "../components/SliceContainer"
import { ContentContainer } from "../components/ContentContainer"

import type { PageDataBodyFaqFragment } from "../graphql.gen"
import * as styles from "./PageDataBodyFaq.module.css"

type Props = ReturnType<typeof mapDataToProps>

const OpenIndicator = () => {
	return (
		<div className={styles.openIndicator}>
			<div />
			<div />
		</div>
	)
}

const Faq = ({ question, answer }: Props["questions"][number]) => {
	return (
		<Collapsible.Root className={styles.faqItem}>
			<Collapsible.Trigger className={styles.question}>
				<OpenIndicator />
				<div>{question}</div>
			</Collapsible.Trigger>

			<Collapsible.Content className={styles.answer}>
				{isFilled.richText(answer) && (
					<RichText
						field={answer}
						className={styles.richText}
						componentOverrides={{
							paragraph: (props) => (
								<p className={styles.paragraph}>{props.children}</p>
							),
						}}
					/>
				)}
			</Collapsible.Content>
		</Collapsible.Root>
	)
}

export const PageDataBodyFaq = ({
	heading,
	link,
	linkText,
	questions,
}: Props) => {
	return (
		<SliceContainer backgroundColor="white">
			<ContentContainer variant="narrow">
				{heading && <h2 className={styles.heading}>{heading}</h2>}

				<div className={styles.questions}>
					{questions.map((item) => (
						<Faq key={item.question} {...item} />
					))}
				</div>

				{link && (
					<LinkWithArrow href={link} className={styles.link}>
						{linkText}
					</LinkWithArrow>
				)}
			</ContentContainer>
		</SliceContainer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyFaqFragment>) {
	return {
		heading: data.primary.heading?.text,
		link: data.primary.link?.url,
		linkText: data.primary.link_text,
		questions: data.items.map((item) => ({
			question: item.question,
			answer: item.answer?.richText,
			open: false,
		})),
	}
}

export const fragment = graphql`
	fragment PageDataBodyFaq on PrismicPageDataBodyFaq {
		primary {
			heading {
				text
			}
			link {
				url
				target
			}
			link_text
		}

		items {
			question
			answer {
				richText
			}
		}
	}
`

export default PageDataBodyFaq
