// extracted by mini-css-extract-plugin
export var button = "PageDataBodyStoryCards-module--button--ItbKm";
export var buttonsContainer = "PageDataBodyStoryCards-module--buttonsContainer--YfqMZ";
export var cardContainer = "PageDataBodyStoryCards-module--cardContainer--+jKzW";
export var cardHeading = "PageDataBodyStoryCards-module--cardHeading--nvhJk";
export var cardSubheading = "PageDataBodyStoryCards-module--cardSubheading--rE4lH";
export var container = "PageDataBodyStoryCards-module--container---oXjC";
export var footnote = "PageDataBodyStoryCards-module--footnote--Ih1bp";
export var header = "PageDataBodyStoryCards-module--header--qfXGQ";
export var imageContainer = "PageDataBodyStoryCards-module--imageContainer--NYswi";
export var images = "PageDataBodyStoryCards-module--images--lDq8c";
export var inner = "PageDataBodyStoryCards-module--inner--prtzR";
export var kicker = "PageDataBodyStoryCards-module--kicker--szoiF";
export var subContainer = "PageDataBodyStoryCards-module--subContainer--qj5rK";
export var subheading = "PageDataBodyStoryCards-module--subheading--vCnRx";
export var textContainer = "PageDataBodyStoryCards-module--textContainer--SzdA4";
export var wheel = "PageDataBodyStoryCards-module--wheel--jhmkd";
export var wheelContainer = "PageDataBodyStoryCards-module--wheelContainer--KJFPT";