import * as React from "react"
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import { PrismicProvider } from "@prismicio/react"
import { repositoryConfigs } from "../prismic/previews"
import { Link } from "gatsby"

interface LinkProps extends React.ComponentPropsWithoutRef<"a"> {
	href: string
}

const InternalLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
	({ href, ...props }, ref) => {
		//@ts-expect-error - Forward Ref types. It's an anchor tag at the end of the
		//day, so safe to ignore.
		return <Link ref={ref} to={href} {...props} />
	},
)
InternalLink.displayName = "InternalLink"

const ExternalLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
	({ href, ...props }, ref) => {
		return (
			<a
				ref={ref}
				href={href}
				{...props}
				target="_blank"
				rel="noopener noreferrer nofollow"
			/>
		)
	},
)
ExternalLink.displayName = "ExternalLink"

interface ProvidersProps {
	children: React.ReactNode
}

export const Providers = ({ children }: ProvidersProps) => {
	return (
		<PrismicProvider
			internalLinkComponent={InternalLink}
			externalLinkComponent={ExternalLink}
		>
			<PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
				{children}
			</PrismicPreviewProvider>
		</PrismicProvider>
	)
}
