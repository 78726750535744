import clsx from 'clsx'
import * as styles from '../styles/EmblaCarousel.module.css'

interface ButtonProps {
  enabled?: boolean
  onClick?: () => void
  className?: string
}

export const PrevButton = ({ enabled, onClick, className }: ButtonProps) => (
  <button
    className={clsx(
      styles.emblaPrev,
      className
    )}
    onClick={onClick}
    disabled={!enabled}
    title="previous"
  />
);