import * as React from "react"
import clsx from "clsx"
import { PrismicLink } from "@prismicio/react"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import {
	GatsbyImage,
	StaticImage,
	type IGatsbyImageData,
} from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { useLocation } from "@gatsbyjs/reach-router"
import { isFilled } from "@prismicio/helpers"

import { RichText } from "../components/RichText"
import { Button } from "../components/Button"
import { LinkWithArrow } from '../components/LinkWithArrow'
import { SliceContainer } from "../components/SliceContainer"

import type { NewsPageQuery, NewsCategoryQuery } from "../graphql.gen"

import * as styles from "./PageDataBodyAllNewsPosts.module.css"
import { ContentContainer } from "../components/ContentContainer"

export const dateFormatter = new Intl.DateTimeFormat("en-us", {
	month: "numeric",
	day: "numeric",
	year: "numeric",
})

interface ExtraCardProps {
	reference?: HTMLDivElement
}

const PostCard = ({
	image,
	imageAlt,
	title,
	href,
	summary,
	tags,
	publishedAt,
	reference,
}: Props["posts"][number] & ExtraCardProps) => {
	const publishDateParts = publishedAt
		? dateFormatter
				.formatToParts(new Date(publishedAt))
				.filter((part) => part.type !== "literal")
		: undefined

	return (
		<li className={styles.postCard} ref={reference}>
			{tags && <h4 className={styles.tags}>{tags.join(", ")}</h4>}

			<div className={styles.postInfo}>
				<PrismicLink href={href} className={styles.postImage}>
					{image ? (
						<GatsbyImage
							image={image}
							objectFit="cover"
							objectPosition="center"
							alt={imageAlt || ""}
						/>
					) : (
						<StaticImage
							src="../assets/default-image.png"
							alt=""
							breakpoints={[320, 400]}
							layout="fullWidth"
						/>
					)}
				</PrismicLink>

				<div className={styles.postCopy}>
					{publishDateParts && (
						<p className={styles.published}>
							{publishDateParts[0].value}.{publishDateParts[1].value}.
							{publishDateParts[2].value}
						</p>
					)}

					<h3 className={styles.postTitle}>{title}</h3>

					{isFilled.richText(summary) && (
						<RichText
							field={summary}
							componentOverrides={{
								paragraph: (props) => (
									<p className={styles.summary}>{props.children}</p>
								),
							}}
						/>
					)}

					<LinkWithArrow className={styles.postButton} href={href}>
						Read Story
					</LinkWithArrow>
				</div>
			</div>
		</li>
	)
}

type Props = ReturnType<typeof mapDataToProps>

interface LocationState {
	category?: string
	hasUserNavigated?: boolean
}

export const PageDataBodyAllNewsPosts = ({
	posts,
	categories,
	totalPages,
	currentPage,
	paginationIndexPath,
}: Props) => {
	const NEWS_POSTS_PER_PAGE = 8
	const containerRef = React.useRef<HTMLDivElement>(null)
	const location = useLocation()
	const locationState = location.state as LocationState | undefined

	function navigateToCategoryPage(e: React.ChangeEvent<HTMLSelectElement>) {
		const category = e.target.value

		if (category === "all") {
			return navigate("/news/", { state: { hasUserNavigated: true } })
		}

		if (categories.includes(category)) {
			const categoryParam = category.toLowerCase()

			return navigate(`/news/${categoryParam}/`, {
				state: { category, hasUserNavigated: true },
			})
		}
	}

	React.useEffect(() => {
		if (!locationState?.hasUserNavigated) return

		containerRef.current?.scrollIntoView()
	}, [locationState?.hasUserNavigated])

	return (
		<SliceContainer backgroundColor="white">
			<ContentContainer>
				<label className={styles.category}>
					<span>Filter by Category</span>
					<select
						className={styles.dropdown}
						onChange={navigateToCategoryPage}
						defaultValue={locationState?.category}
					>
						<option value="all">All</option>
						{categories.map((category: string) => (
							<option key={category} value={category}>
								{category}
							</option>
						))}
					</select>
				</label>

				<ul className={styles.posts}>
					{posts.map((post, index: number) => (
						<PostCard
							key={post.title}
							reference={
								index === NEWS_POSTS_PER_PAGE * currentPage - 1
									? containerRef
									: null
							}
							{...post}
						/>
					))}
				</ul>

				<div className={styles.loadMore}>
					<Button
						href={paginationIndexPath + (currentPage + 1)}
						className={clsx(
							styles.loadMoreButton,
							currentPage === totalPages && styles.hidden,
						)}
						state={{ hasUserNavigated: true }}
					>
						Load More
					</Button>
				</div>
			</ContentContainer>
		</SliceContainer>
	)
}

export interface AllNewsPostsData {
	__typename: "PrismicPageDataBodyAllNewsPosts"
	posts: NewsPageQuery["allPrismicNewsPost"]["nodes"]
	categories: NewsCategoryQuery["allCategory"]["distinct"]
	totalPages: number
	currentPage: number
	paginationIndexPath: string
}

export function mapDataToProps({ data }: MapDataToPropsCtx<AllNewsPostsData>) {
	const posts = data.posts.map((node) => {
		return {
			image: node.data.image?.gatsbyImageData as IGatsbyImageData,
			imageAlt: node.data.image?.alt,
			title: node.data.title?.text,
			summary: node.data.summary?.richText,
			href: node.url,
			tags: node.tags,
			publishedAt: node.data.publish_date,
		}
	})

	return {
		posts,
		categories: data.categories,
		currentPage: data.currentPage,
		totalPages: data.totalPages,
		paginationIndexPath: data.paginationIndexPath,
	}
}

export default PageDataBodyAllNewsPosts
