// extracted by mini-css-extract-plugin
export var bioCard = "PageDataBodyBioCard-module--bioCard--32av0";
export var bioCardContainer = "PageDataBodyBioCard-module--bioCardContainer--aLI-G";
export var bioCardContent = "PageDataBodyBioCard-module--bioCardContent--GnT14";
export var cardGrid = "PageDataBodyBioCard-module--cardGrid--VSBxW";
export var gearImageContainer = "PageDataBodyBioCard-module--gearImageContainer--xSHb8";
export var heading = "PageDataBodyBioCard-module--heading--nSgLX";
export var inner = "PageDataBodyBioCard-module--inner--W+7mz";
export var subHeadingContainer = "PageDataBodyBioCard-module--subHeadingContainer--GV3e8";
export var subHeadingOne = "PageDataBodyBioCard-module--subHeadingOne--R1SOe";
export var title = "PageDataBodyBioCard-module--title--YHPSp";