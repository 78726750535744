import { graphql, useStaticQuery } from "gatsby"
import type { SponsorDataQuery } from "../graphql.gen"
import { useEffect, useRef, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { useOnImagesLoaded } from "../hooks/useOnImagesLoaded"

import * as styles from "./SponsorBanner.module.css"

function useSponsorData() {
	const staticData = useStaticQuery<SponsorDataQuery>(graphql`
		query SponsorData {
			prismicSettings {
				_previewable
				data {
					sponsors_heading {
						text
					}
					sponsors {
						logo {
							url
							alt
						}
					}
				}
			}
		}
	`)
	const { data: mergedData } = useMergePrismicPreviewData(staticData)

	const data = mergedData.prismicSettings?.data
	const heading = data?.sponsors_heading?.text
	const sponsors = data?.sponsors

	return {
		heading,
		sponsors:
			sponsors?.map((item) => ({
				url: item?.logo?.url,
				alt: item?.logo?.alt,
			})) ?? [],
	}
}

export const SponsorBanner = () => {
	const { heading, sponsors } = useSponsorData()
	const [isPlaying, setIsPlaying] = useState(true)
	const [childWidth, setChildWidth] = useState(0)
	const [slides, setSlides] = useState(sponsors)
	const [animation1, setAnimation1] = useState(null)
	const [animation2, setAnimation2] = useState(null)

	const containerRef = useRef(null)
	const group1 = useRef(null)
	const group2 = useRef(null)

	const imagesLoaded = useOnImagesLoaded(group1)

	useEffect(() => {
		if (group1.current) {
			if (imagesLoaded) {
				let newChildWidth = 0
				Array.from(group1?.current.querySelectorAll("div"))?.forEach(
					(child) => {
						newChildWidth += child?.offsetWidth
					},
				)
				setChildWidth(newChildWidth)
			}
		}
	}, [imagesLoaded])

	useEffect(() => {
		if (childWidth > 0) {
			setSlideClones()
			window.addEventListener("resize", setSlideClones)
		}

		return () => {
			window.removeEventListener("resize", setSlideClones)
		}
	}, [childWidth])

	useEffect(() => {
		if (animation1 && animation2) {
			animation1.onfinish = () => animation1.play()
			animation2.onfinish = () => animation2.play()
		}
	}, [animation1, animation2])

	const setSlideClones = () => {
		let newSlides = [...sponsors]
		const windowWidth = window.innerWidth
		const windowDouble = windowWidth * 2
		let newChildWidth = 0

		newChildWidth = childWidth

		let appendCount = 0
		do {
			newChildWidth += childWidth
			appendCount += 1
		} while (newChildWidth <= windowDouble)

		const group1Animate = [
			{ left: `${windowWidth}px` },
			{ left: `${-(newChildWidth - windowWidth)}px` },
		]

		const group2Animate = [
			{ left: `${-(newChildWidth - windowWidth)}px` },
			{ left: `${-(newChildWidth * 2 - windowWidth)}px` },
		]

		const options = {
			duration: window.innerWidth > 768 ? 35000 : 15000,
		}

		setAnimation1(group1?.current?.animate(group1Animate, options))
		setAnimation2(group2?.current?.animate(group2Animate, options))

		for (let i = 0; i < appendCount; i++) {
			newSlides = newSlides.concat([...sponsors])
		}

		setSlides(newSlides)
	}

	const toggleAnimations = () => {
		if (animation1.playState === "running") {
			setIsPlaying(false)
			animation1.pause()
			animation2.pause()
		} else {
			setIsPlaying(true)
			animation1.play()
			animation2.play()
		}
	}

	return (
		<section className={styles.sponsorBanner}>
			{heading && <h2>{heading}</h2>}

			<div className={styles.sponsors}>
				<div className={styles.sponsorSlides} ref={containerRef}>
					<div ref={group1}>
						{slides.map((item, index: number) => (
							<div className={styles.sponsorSlide} key={index}>
								<img alt={item.alt || ""} src={item.url} />
							</div>
						))}
					</div>

					<div ref={group2}>
						{slides.map((item, index: number) => (
							<div className={styles.sponsorSlide} key={index}>
								<img alt={item.alt || ""} src={item.url} />
							</div>
						))}
					</div>
				</div>
			</div>

			<button className={styles.playToggle} onClick={toggleAnimations}>
				{isPlaying ? (
					<StaticImage src="../assets/icon-pause.svg" alt="pause slider" />
				) : (
					<StaticImage src="../assets/icon-play.svg" alt="play slider" />
				)}
			</button>
		</section>
	)
}
