import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"

import type { PageDataBodyInteriorPageHeroFragment } from "../graphql.gen"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import wheelOutline from "../assets/gears-outline-red.svg"
import filledGear from "../assets/gears-inspire.svg"
import { motion } from "framer-motion"
import { useRotateOnScroll } from "../hooks/useRotateOnScroll"

import * as styles from "./PageDataBodyInteriorPageHero.module.css"

type Props = ReturnType<typeof mapDataToProps>

const FilledWheel = () => {
	const rotate = useRotateOnScroll(5)

	return (
		<motion.img
			src={filledGear}
			className={styles.filledGear}
			loading="lazy"
			decoding="async"
			style={{ rotate }}
		/>
	)
}

const TopWheel = () => {
	const rotate = useRotateOnScroll(5)

	return (
		<motion.img
			src={wheelOutline}
			className={styles.topGear}
			loading="lazy"
			decoding="async"
			style={{ rotate }}
		/>
	)
}

const BottomWheel = () => {
	const rotate = useRotateOnScroll(5)

	return (
		<motion.img
			src={wheelOutline}
			className={styles.bottomGear}
			loading="lazy"
			decoding="async"
			style={{ rotate }}
		/>
	)
}

export const PageDataBodyInteriorPageHero = ({
	coverImage,
	heading,
}: Props) => {
	return (
		<section className={styles.container}>
			<div className={styles.subContainer}>
				<GatsbyImage
					image={coverImage}
					alt=""
					objectFit="cover"
					objectPosition="center"
					className={styles.coverImage}
				/>
				<TopWheel />
				<FilledWheel />
				<BottomWheel />
				<div className={styles.headerContainer}>
					<h1 className={styles.header}>{heading}</h1>
				</div>
			</div>
		</section>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyInteriorPageHeroFragment>) {
	return {
		coverImage: data.primary.cover_image?.gatsbyImageData as IGatsbyImageData,
		heading: data.primary.heading?.text,
	}
}

export const fragment = graphql`
	fragment PageDataBodyInteriorPageHero on PrismicPageDataBodyInteriorPageHero {
		primary {
			cover_image {
				gatsbyImageData(width: 700)
			}
			heading {
				text
			}
		}
	}
`

export default PageDataBodyInteriorPageHero
