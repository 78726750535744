import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { SliceContainer } from "../components/SliceContainer"

import type { PageDataBodyBioCardFragment } from "../graphql.gen"

import * as styles from "./PageDataBodyBioCard.module.css"
import * as typography from "../styles/typography.module.css"
import { type IGatsbyImageData } from "gatsby-plugin-image"
import clsx from "clsx"
import { GearGatsbyImage } from "../components/GearGatsbyImage"

type Props = ReturnType<typeof mapDataToProps>

type BioProps = {
	image?: IGatsbyImageData
	heading?: string
	subheading1?: string
	subheading2?: string
}

export const PageDataBodyBioCard = ({ title, cards }: Props) => {
	return (
		<SliceContainer backgroundColor="blue">
			{title && (
				<h2 className={clsx(styles.title, typography.heading4)}>{title}</h2>
			)}

			{cards && (
				<div className={styles.cardGrid}>
					{cards.map((card, index) => {
						return (
							<BioCard
								key={index}
								image={card.image}
								heading={card.heading}
								subheading1={card.subheadingOne}
								subheading2={card.subheadingTwo}
							/>
						)
					})}
				</div>
			)}
		</SliceContainer>
	)
}

const BioCard = ({ image, heading, subheading1, subheading2 }: BioProps) => {
	return (
		<div className={styles.bioCardContainer}>
			{image && (
				<div className={styles.gearImageContainer}>
					<GearGatsbyImage image={image} className={styles.inner} />
				</div>
			)}

			<div className={styles.bioCard} data-with-image={Boolean(image)}>
				<div className={styles.bioCardContent}>
					{heading && (
						<h2 className={clsx(styles.heading, typography.heading5)}>
							{heading}
						</h2>
					)}

					<div className={styles.subHeadingContainer}>
						{subheading1 && (
							<h5 className={clsx(styles.subHeadingOne, typography.paragraph2)}>
								{subheading1}
							</h5>
						)}
						{subheading2 && (
							<p className={typography.paragraph2}>{subheading2}</p>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyBioCardFragment>) {
	return {
		title: data.primary.page_title?.text,
		cards: data.items.map((item) => {
			return {
				image: item.photo?.gatsbyImageData as IGatsbyImageData,
				heading: item.heading?.text,
				subheadingOne: item.subheading_1?.text,
				subheadingTwo: item.subheading_2,
			}
		}),
	}
}

export const fragment = graphql`
	fragment PageDataBodyBioCard on PrismicPageDataBodyBioCard {
		primary {
			page_title {
				text
			}
		}

		items {
			photo {
				gatsbyImageData(layout: FULL_WIDTH, breakpoints: [234, 500, 700])
			}
			heading {
				text
			}
			subheading_1 {
				text
			}
			subheading_2
		}
	}
`

export default PageDataBodyBioCard
