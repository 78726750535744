import clsx from 'clsx';
import * as styles from '../styles/EmblaCarousel.module.css'

interface ButtonProps {
  selected?: boolean
  onClick?: () => void,
}

export const DotButton = ({ selected, onClick }: ButtonProps) => (
  <button
    className={clsx(
      styles.emblaDot,
      selected && styles.emblaDotSelected
    )}
    type="button"
    onClick={onClick}
  />
);