// extracted by mini-css-extract-plugin
export var arrow = "PageDataBodyDownloadAssets-module--arrow--wIXx5";
export var assetGrid = "PageDataBodyDownloadAssets-module--assetGrid--RjbQF";
export var container = "PageDataBodyDownloadAssets-module--container--jTnU6";
export var grid = "PageDataBodyDownloadAssets-module--grid--qHvk5";
export var header = "PageDataBodyDownloadAssets-module--header--ZZb2O";
export var heading = "PageDataBodyDownloadAssets-module--heading--mhOTL";
export var largeThumbnailContainer = "PageDataBodyDownloadAssets-module--largeThumbnailContainer--B7+dP";
export var largeThumbnailHeader = "PageDataBodyDownloadAssets-module--largeThumbnailHeader--AxdWS";
export var largeThumbnailLink = "PageDataBodyDownloadAssets-module--largeThumbnailLink--9KgbF";
export var link = "PageDataBodyDownloadAssets-module--link--VsOZq";
export var listViewContainer = "PageDataBodyDownloadAssets-module--listViewContainer--5Ls0I";
export var paragraph = "PageDataBodyDownloadAssets-module--paragraph--z5mBa";
export var richText = "PageDataBodyDownloadAssets-module--richText--8Qvxs";
export var simage = "PageDataBodyDownloadAssets-module--simage--iSN9h";
export var smallThumbnailContainer = "PageDataBodyDownloadAssets-module--smallThumbnailContainer--vryoB";
export var smallThumbnailContent = "PageDataBodyDownloadAssets-module--smallThumbnailContent--nnUBZ";
export var smallThumbnailGrid = "PageDataBodyDownloadAssets-module--smallThumbnailGrid--gJM0l";