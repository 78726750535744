import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { isFilled } from "@prismicio/helpers"
import { motion } from "framer-motion"
import { IGatsbyImageData } from "gatsby-plugin-image"

import { RichText } from "../components/RichText"
import { useRotateOnScroll } from "../hooks/useRotateOnScroll"
import researchWheel from "../assets/gears-research.svg"
import riderWheel from "../assets/gears-ride.svg"
import survivorWheel from "../assets/gears-survive.svg"
import { GearGatsbyImage } from "../components/GearGatsbyImage"
import type { StoryDataBodyCopyWithSidebarFragment } from "../graphql.gen"

import * as aspectRatio from "../styles/aspect-ratio.module.css"
import * as typography from "../styles/typography.module.css"
import * as styles from "./StoryDataBodyCopyWithSidebar.module.css"
import clsx from "clsx"
import { SliceContainer } from "../components/SliceContainer"
import { ContentContainer } from "../components/ContentContainer"

type Props = ReturnType<typeof mapDataToProps>

const Wheel = ({ gear }) => {
	const rotate = useRotateOnScroll(5)

	return (
		<motion.img
			src={
				gear === 'Rider Story'
				? riderWheel
				: gear === 'Survivor Story'
				? survivorWheel
				: researchWheel
			}
			className={styles.wheel}
			loading="lazy"
			decoding="async"
			style={{ rotate }}
		/>
	)
}

export const StoryDataBodyCopyWithSidebar = ({
	gearType,
	copyText,
	quote,
	quotee,
	quoteeTitle,
	heading,
	sideImage,
	sideImageAlt,
}: Props) => {
	return (
		<SliceContainer className={styles.container} backgroundColor="white">
			<ContentContainer className={styles.subContainer} variant="wide">
				<div>
					{heading && (
						<h2 className={clsx(typography.heading2, styles.heading)}>
							{heading}
						</h2>
					)}

					{isFilled.richText(copyText) && (
						<RichText
							field={copyText}
							componentOverrides={{
								paragraph: (props) => (
									<p className={clsx(typography.paragraph1, styles.pText)}>
										{props.children}
									</p>
								),
							}}
						/>
					)}
				</div>

				<div className={styles.sidebar}>
					<Wheel gear={gearType} />

					{sideImage && (
						<div
							className={clsx(aspectRatio.container, styles.gearImageContainer)}
						>
							<GearGatsbyImage image={sideImage} alt={sideImageAlt} />
						</div>
					)}

					<figure>
						{quote && (
							<blockquote className={clsx(typography.quote, styles.quote)}>
								“{quote}”
							</blockquote>
						)}

						<figcaption className={styles.caption}>
							{quotee && (
								<p className={clsx(typography.quotee, styles.quotee)}>
									— {quotee}
								</p>
							)}
							{quoteeTitle && (
								<p className={styles.quoteeTitle}>{quoteeTitle}</p>
							)}
						</figcaption>
					</figure>
				</div>
			</ContentContainer>
		</SliceContainer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<StoryDataBodyCopyWithSidebarFragment>) {
	return {
		gearType: data.primary.gear_type,
		copyText: data.primary.copy_text?.richText,
		quote: data.primary.quote,
		quotee: data.primary.quotee,
		quoteeTitle: data.primary.quotee_position,
		heading: data.primary.header?.text,
		sideImage: data.primary.sidebar_image?.gatsbyImageData as IGatsbyImageData,
		sideImageAlt: data.primary.sidebar_image?.alt,
	}
}

export const fragment = graphql`
	fragment StoryDataBodyCopyWithSidebar on PrismicStoryDataBodyCopyWithSidebar {
		primary {
			gear_type
			copy_text {
				richText
			}
			quote
			quotee
			quotee_position
			header {
				text
			}
			sidebar_image {
				gatsbyImageData(layout: FULL_WIDTH)
				alt
			}
		}
	}
`

export default StoryDataBodyCopyWithSidebar
