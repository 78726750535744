/**
 * This file contains a template for all News Post documents in your Prismic
 * This file contains a template for all Story documents in your Prismic
 * repository. It uses Gatsby's File System Route API to automatically generate
 * a page for each document using its `url` field. The `url` field is computed
 * using your app's Link Resolver.
 *
 * This template supports Prismic previews using the `withPrismicPreview` higher
 * order component.
 *
 * @see https://www.gatsbyjs.com/docs/reference/routing/file-system-route-api/
 */

import { graphql, type PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { MapSlicesToComponents } from "../components/MapSlicesToComponents"
import { Header } from "../components/Header"
import { PostTitle } from "../components/PostTitle"
import { SEO } from "../components/SEO"
import type { StoryTemplateQuery } from "../graphql.gen"
import { useSiteSettings } from "../hooks/useSiteSettings"

const StoryTemplate = ({ data }: PageProps<StoryTemplateQuery>) => {
	const story = data.prismicStory

	return (
		<>
			<Header />

			<PostTitle
				title={story?.data.title?.text}
				parent="Stories"
				parentUrl="/stories"
			/>

			<MapSlicesToComponents slices={story?.data.body} />
		</>
	)
}

export const Head = ({ data }: HeadProps<StoryTemplateQuery>) => {
	const settings = useSiteSettings()
	const story = data.prismicStory

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle={story?.data.title?.text}
			meta={{
				description: story?.data.meta_description?.text,
				title: story?.data.meta_title?.text,
			}}
			twitter={{
				username: settings.twitter.username,
				cardImageUrl:
					story?.data.twitter_card?.url ?? settings.twitter.cardImageUrl,
			}}
			openGraph={{
				cardImageUrl:
					story?.data.open_graph_image?.url ?? settings.openGraph.cardImageUrl,
			}}
		/>
	)
}

/**
 * When a Prismic preview session is active, `withPrismicPreview` will
 * automatically provide your template with updated preview content. As editors
 * edit and save content in the Prismic writing room, the page will
 * automatically refresh to display the edited content.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#withprismicpreview
 */
export default withPrismicPreview(StoryTemplate)

export const query = graphql`
	query StoryTemplate($id: String!) {
		prismicStory(id: { eq: $id }) {
			_previewable
			data {
				meta_description {
					text
				}
				meta_title {
					text
				}
				twitter_card {
					url(imgixParams: { width: 800, height: 418, q: 75 })
				}
				open_graph_image {
					url(imgixParams: { width: 1200, height: 630, q: 75 })
				}

				title {
					text
				}
				image {
					alt
					gatsbyImageData(
						width: 900
						breakpoints: [400, 600]
						layout: FULL_WIDTH
					)
				}

				body {
					__typename
					...StoryDataBody
				}
			}
		}
	}
`
