// extracted by mini-css-extract-plugin
export var buttonContainer = "PageDataBodyGearPromo-module--buttonContainer--tlCpv";
export var buttonTwo = "PageDataBodyGearPromo-module--buttonTwo--9qylb";
export var buttons = "PageDataBodyGearPromo-module--buttons--bNQtO";
export var container = "PageDataBodyGearPromo-module--container--Ecrhx";
export var content = "PageDataBodyGearPromo-module--content--aEhX-";
export var copy = "PageDataBodyGearPromo-module--copy--yM2bG";
export var gearImageWrapper = "PageDataBodyGearPromo-module--gearImageWrapper--SSOY-";
export var images = "PageDataBodyGearPromo-module--images--qQwek";
export var list = "PageDataBodyGearPromo-module--list--cHIZd";
export var subContainer = "PageDataBodyGearPromo-module--subContainer--hOZqo";
export var text = "PageDataBodyGearPromo-module--text--TYSIs";