import { graphql } from "gatsby"
import type * as pt from "@prismicio/types"
import clsx from "clsx"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { isFilled } from "@prismicio/helpers"
import { motion } from "framer-motion"

import { RichText } from "../components/RichText"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { Link } from "../components/Link"
import type { PageDataBodyStoryCardsFragment } from "../graphql.gen"
import { Button } from "../components/Button"
import { useRotateOnScroll } from "../hooks/useRotateOnScroll"
import { SliceContainer } from "../components/SliceContainer"

import wheel from "../assets/gears-crush-navy.svg"
import * as typography from "../styles/typography.module.css"
import * as styles from "./PageDataBodyStoryCards.module.css"

const Wheel = () => {
	const rotate = useRotateOnScroll(5)

	return (
		<motion.img
			src={wheel}
			className={styles.wheel}
			loading="lazy"
			decoding="async"
			style={{ rotate }}
		/>
	)
}

type Props = ReturnType<typeof mapDataToProps> & {
	footnote?: pt.RichTextField
	withWheel?: boolean
	className?: string
	landing?: boolean
}

export const PageDataBodyStoryCards = ({
	heading,
	copy,
	buttonOneLink,
	buttonOneText,
	buttonTwoLink,
	buttonTwoText,
	footnote,
	withWheel = true,
	className,
	landing,
	cards,
}: Props) => {
	return (
		<SliceContainer
			className={clsx(styles.container, className)}
			backgroundColor="red"
			data-background="red"
		>
			{withWheel && (
				<div className={styles.wheelContainer}>
					<Wheel />
				</div>
			)}

			<div className={styles.subContainer}>
				{heading && (
					<h2 className={clsx(typography.heading2, styles.header)}>
						{heading}
					</h2>
				)}

				{isFilled.richText(copy) && (
					<RichText
						field={copy}
						className={clsx(typography.paragraph1, styles.subheading)}
					/>
				)}

				{cards && (
					<div className={styles.images}>
						{cards.map((card, index) => {
							const Component = card.href ? Link : "div"

							return (
								<Component
									key={index}
									className={styles.cardContainer}
									href={card.href}
								>
									{card.image && (
										<div className={styles.imageContainer}>
											<GatsbyImage
												image={card.image}
												className={clsx(styles.inner)}
												alt={card.imageAlt ?? ""}
											/>
										</div>
									)}

									<div className={styles.textContainer}>
										{card.heading && (
											<h3 className={styles.cardHeading}>{card.heading}</h3>
										)}

										{card.subheading && (
											<h4 className={styles.cardSubheading}>
												{card.subheading}
											</h4>
										)}
									</div>
								</Component>
							)
						})}
					</div>
				)}

				{(buttonOneLink || buttonTwoLink) && (
					<div className={styles.buttonsContainer}>
						<Button className={styles.button} href={buttonOneLink}>
							{buttonOneText}
						</Button>

						<Button className={styles.button} href={buttonTwoLink}>
							{buttonTwoText}
						</Button>
					</div>
				)}

				{isFilled.richText(footnote) && (
					<RichText
						field={footnote}
						className={clsx(typography.paragraph1, styles.footnote)}
					/>
				)}
			</div>
		</SliceContainer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyStoryCardsFragment>) {
	const cards = data.items.map((item) => {
		if (!item.story?.document) return
		if (!("data" in item.story.document)) return

		const story = item.story.document.data

		return {
			href: item.story.document.url,
			image: story.image?.gatsbyImageData as IGatsbyImageData,
			imageAlt: story.image?.alt,
			heading: story.title?.text,
			subheading: story.summary?.text,
		}
	})

	type Card = NonNullable<typeof cards[number]>

	return {
		heading: data.primary.heading?.text,
		copy: data.primary.copy?.richText,
		buttonOneLink: data.primary.button_1_link?.url,
		buttonOneText: data.primary.button_1_text,
		buttonTwoLink: data.primary.button_2_link?.url,
		buttonTwoText: data.primary.button_2_text,
		cards: cards.filter(Boolean) as Card[],
	}
}

export const fragment = graphql`
	fragment PageDataBodyStoryCards on PrismicPageDataBodyStoryCards {
		primary {
			heading {
				text
			}
			copy {
				richText
			}
			button_1_link {
				url
			}
			button_1_text

			button_2_link {
				url
			}
			button_2_text
		}

		items {
			story {
				document {
					... on PrismicStory {
						_previewable
						url
						data {
							title {
								text
							}
							image {
								alt
								gatsbyImageData(
									width: 700
									layout: FULL_WIDTH
									breakpoints: [234, 500, 700]
								)
							}
							summary {
								text
							}
						}
					}
					__typename
				}
			}
		}
	}
`

export default PageDataBodyStoryCards
