import { isFilled } from "@prismicio/helpers"
import { RichText } from "./RichText"

import * as styles from "./IllustrationCard.module.css"
import { RemoteImage } from "./RemoteImage"

interface Props {
	imgUrl?: string
	imgAlt?: string
	imgDimensions?: { width: number; height: number }
	title: string | undefined
	copy: any
}

// TODO: This + <CTACard /> should really just be one single component with
// different variants.
export const IllustrationCard = ({
	imgUrl,
	imgAlt,
	imgDimensions,
	title,
	copy,
}: Props) => {
	return (
		<div className={styles.container}>
			{imgUrl && (
				<RemoteImage
					src={imgUrl}
					alt={imgAlt ?? ""}
					className={styles.image}
					width={imgDimensions?.width}
					height={imgDimensions?.height}
				/>
			)}

			{title && <h3 className={styles.title}>{title}</h3>}

			{isFilled.richText(copy) && (
				<RichText field={copy} className={styles.copy} />
			)}
		</div>
	)
}
