import { useEffect, useState } from 'react'
import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import { Link } from '../components/Link'
import clsx from 'clsx'

import type { PageDataBodyPartnerGridFragment } from "../graphql.gen"

import * as styles from "./PageDataBodyPartnerGrid.module.css"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyPartnerGrid = ({ tiers }: Props) => {
  const [allPartners, setAllPartners] = useState([])
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    let partnerList = []

    tiers.forEach((tier) => {
      partnerList = partnerList.concat(tier.partners)
    })

    setAllPartners(partnerList)
  }, [tiers])

  const Component = (props) => {
    if (props.href) {
      return (
        <Link
          className={styles.partner}
          href={props.href}
          target={props.target}
        >
          {props.children}
        </Link>
      )
    } else {
      return <div className={styles.partner}>{props.children}</div>
    }
  }

  return tiers.length > 0 ? (
    <section className={styles.partnerGrid}>
      <div role="tablist" className={styles.tabs}>
        <button
          className={clsx(
            styles.tab,
            activeTab === 0 && styles.selected
          )}
          id="tab-1"
          type="button"
          role="tab"
          aria-selected={activeTab === 0 ? true : false}
          aria-controls="tabpanel-1"
          onClick={() => setActiveTab(0)}
        >
          All
        </button>

        {tiers.map((tier, index: number) => (
          <button
            className={clsx(
              styles.tab,
              activeTab === index + 1 && styles.selected
            )}
            key={tier.label}
            id={`tab-${index + 2}`}
            type="button"
            role="tab"
            aria-selected={activeTab === index + 1 ? true : false}
            aria-controls={`tabpanel-${index + 2}`}
            onClick={() => setActiveTab(index + 1)}
          >
            {tier.label}
          </button>
        ))}
      </div>

      <div id="tabpanel-1" role="tabpanel" aria-labelledby='tab-1' className={clsx(
        styles.partners,
        activeTab === 0 && styles.show
      )}>
        {allPartners.map((partner) => (
          <>
            {partner?.logo?.gatsbyImageData ? (
              <Component href={partner?.link?.url} target={partner?.link?.target}>
                <GatsbyImage
                  image={partner.logo.gatsbyImageData}
                  alt={partner.logo.alt || ""}
                  objectFit="contain"
                  objectPosition="center"
                />
              </Component>
            ) : null}
          </>
        ))}
      </div>

      {tiers.map((tier, index: number) => (
        <div key={tier.label} id={`tabpanel-${index + 2}`} role="tabpanel" aria-labelledby={`tab-${index + 2}`} className={clsx(
          styles.partners,
          activeTab === index + 1 && styles.show
        )}>
          {tier?.partners?.map((partner) => (
            <>
              {partner?.logo?.gatsbyImageData ? (
                <Component href={partner?.link?.url} target={partner?.link?.target}>
                  <GatsbyImage
                    image={partner.logo.gatsbyImageData}
                    alt={partner.logo.alt || ""}
                    objectFit="contain"
                    objectPosition="center"
                  />
                </Component>
              ) : null}
            </>
          ))}
        </div>
      ))}
    </section>
  ) : null
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyPartnerGridFragment>) {
	return {
    tiers: data.items.map((item) => {
      return {
        label: item?.partner_tier?.document?.data?.tier_label,
        partners: item?.partner_tier?.document?.data?.partners
      }
    })
  }
}

export const fragment = graphql`
  fragment PageDataBodyPartnerGrid on PrismicPageDataBodyPartnerGrid {
    items {
      partner_tier {
        id
        document {
          ... on PrismicPartnerTier {
            data {
              tier_label
              partners {
                logo {
                  gatsbyImageData(layout: FULL_WIDTH)
                  alt
                }
                link {
                  url
                  target
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PageDataBodyPartnerGrid
