// extracted by mini-css-extract-plugin
export var caption = "StoryDataBodyCopyWithSidebar-module--caption--61fH6";
export var container = "StoryDataBodyCopyWithSidebar-module--container--nJgd5";
export var gearImageContainer = "StoryDataBodyCopyWithSidebar-module--gearImageContainer--A+tsW";
export var heading = "StoryDataBodyCopyWithSidebar-module--heading--WKs-L";
export var pText = "StoryDataBodyCopyWithSidebar-module--pText--izJcE";
export var quote = "StoryDataBodyCopyWithSidebar-module--quote--2JCI5";
export var quotee = "StoryDataBodyCopyWithSidebar-module--quotee--AfMiK";
export var quoteeTitle = "StoryDataBodyCopyWithSidebar-module--quoteeTitle--NXIVM";
export var sidebar = "StoryDataBodyCopyWithSidebar-module--sidebar--CbnBd";
export var subContainer = "StoryDataBodyCopyWithSidebar-module--subContainer--3PVFc";
export var wheel = "StoryDataBodyCopyWithSidebar-module--wheel--SfDwP";