import { graphql } from "gatsby"
import clsx from "clsx"
import { motion } from "framer-motion"

import type { PageDataBodyIllustrationsCtaSFragment } from "../graphql.gen"
import { Button } from "../components/Button"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { CtaCard } from "../components/CtaCard"
import { useRotateOnScroll } from "../hooks/useRotateOnScroll"
import { SliceContainer } from "../components/SliceContainer"
import wheel from "../assets/gears-empower-navy.svg"

import * as typography from "../styles/typography.module.css"
import * as styles from "./PageDataBodyIllustrationsCtas.module.css"

type Props = ReturnType<typeof mapDataToProps>

const Wheel = () => {
	const rotate = useRotateOnScroll(5)

	return (
		<motion.img
			src={wheel}
			className={styles.wheel}
			loading="lazy"
			decoding="async"
			style={{ rotate }}
		/>
	)
}

export const PageDataBodyIllustrationsCtaS = ({
	heading,
	buttonText,
	buttonLink,
	cards,
}: Props) => {
	return (
		<SliceContainer backgroundColor="red" className={styles.container} data-background="red">
			<div className={styles.wheelContainer}>
				<Wheel />
			</div>

			<div className={styles.content}>
				{heading && (
					<h2 className={clsx(typography.heading2, styles.heading)}>
						{heading}
					</h2>
				)}

				{cards && (
					<div className={styles.cards}>
						{cards.map((card, index) => (
							<CtaCard
								key={index}
								imgUrl={card.imgUrl}
								imgAlt={card.imgAlt}
								imgDimensions={card.imgDimensions}
								title={card.heading}
								copy={card.copy}
								link={card.link}
								linkText={card.linkText}
							/>
						))}
					</div>
				)}

				{buttonLink && buttonText && (
					<Button className={styles.button} href={buttonLink}>
						{buttonText}
					</Button>
				)}
			</div>
		</SliceContainer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyIllustrationsCtaSFragment>) {
	return {
		heading: data.primary.header?.text,
		buttonText: data.primary.button_text,
		buttonLink: data.primary.button_link?.url,
		cards: data.items.map((item) => {
			return {
				heading: item.card_heading?.text,
				imgUrl: item.card_svg?.url,
				imgAlt: item.card_svg?.alt,
				imgDimensions: item.card_svg?.dimensions,
				copy: item.copy_text?.richText,
				link: item.link?.url,
				linkText: item.link_text,
			}
		}),
	}
}

export const fragment = graphql`
	fragment PageDataBodyIllustrationsCtaS on PrismicPageDataBodyIllustrationsCtaS {
		primary {
			header {
				text
			}
			button_text
			button_link {
				url
			}
		}

		items {
			card_heading {
				text
			}
			card_svg {
				alt
				url
				dimensions {
					width
					height
				}
			}
			copy_text {
				richText
			}
			link {
				url
			}
			link_text
		}
	}
`

export default PageDataBodyIllustrationsCtaS
