import * as React from 'react'
import clsx from 'clsx'
import * as styles from './MenuButton.module.css'

interface MenuButtonProps {
  open?: boolean
  onClick?: () => void
}
export const MenuButton = ({
  open,
  onClick
}: MenuButtonProps) => {
  return (
    <button
      className={clsx(
        styles.menuToggle,
        open && styles.menuToggleActive
      )}
      aria-label="Toggle Primary Navigation"
      onClick={onClick}
    >
      <span />
    </button>
  )
}