// extracted by mini-css-extract-plugin
export var actionButtons = "why-i-ride-module--actionButtons--0+0aa";
export var addToWall = "why-i-ride-module--addToWall--jUUqb";
export var background = "why-i-ride-module--background--7ZnOM";
export var cardPreview = "why-i-ride-module--cardPreview--utxSQ";
export var cardVertical = "why-i-ride-module--cardVertical--RT0EF";
export var download = "why-i-ride-module--download--1zQFl";
export var downloadButtons = "why-i-ride-module--downloadButtons--HpdoX";
export var goBack = "why-i-ride-module--goBack--D9b5e";
export var instructions = "why-i-ride-module--instructions--XbfnH";
export var loadMore = "why-i-ride-module--loadMore--yyL11";
export var reason = "why-i-ride-module--reason--su7m6";
export var reasons = "why-i-ride-module--reasons--kN4Wp";
export var show = "why-i-ride-module--show--gSZQX";
export var theWall = "why-i-ride-module--theWall--qMp5F";
export var wallEdit = "why-i-ride-module--wallEdit--nyFmC";
export var wallHero = "why-i-ride-module--wallHero--Aj549";
export var wallShare = "why-i-ride-module--wallShare--vN9Rf";