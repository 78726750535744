import { graphql } from "gatsby"
import clsx from "clsx"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import { isFilled } from "@prismicio/helpers"

import { RichText } from "../components/RichText"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { Button } from "../components/Button"
import { LinkWithArrow } from "../components/LinkWithArrow"

import type { PageDataBodyRideOptionDetailsFragment } from "../graphql.gen"

import * as typography from "../styles/typography.module.css"
import * as styles from "./PageDataBodyRideOptionDetails.module.css"

type StatProps = Props["stats"][number] & {
	clone?: boolean
}

const Stat = ({ heading, subheading, clone = false }: StatProps) => {
	return (
		<div className={styles.detail} data-clone={clone}>
			{heading && <dt className={styles.detailHeading}>{heading}</dt>}

			{isFilled.richText(subheading) && (
				<RichText
					field={subheading}
					componentOverrides={{
						paragraph: (props) => (
							<dd className={styles.detailText}>{props.children}</dd>
						),
					}}
				/>
			)}
		</div>
	)
}

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyRideOptionDetails = ({
	image,
	imageAlt,
	heading,
	buttonOneLink,
	buttonOneText,
	buttonTwoLink,
	buttonTwoText,
	stats,
}: Props) => {
	const lastStat = stats[stats.length - 1]
	const shouldShowClonedStatForSpacing = stats.length % 2 !== 0

	return (
		<section className={styles.rideOptionDetails}>
			<div className={styles.rideOptionImage}>
				{image && (
					<GatsbyImage
						image={image}
						alt={imageAlt || ""}
						objectFit="cover"
						objectPosition="center"
					/>
				)}
			</div>

			<div className={styles.details}>
				{heading && (
					<h2 className={clsx(typography.heading2, styles.heading)}>
						{heading}
					</h2>
				)}

				<dl className={styles.detailList}>
					{stats.map((item) => (
						<Stat key={item.heading} {...item} />
					))}

					{shouldShowClonedStatForSpacing && <Stat clone {...lastStat} />}
				</dl>

				<div className={styles.buttons}>
					{buttonOneLink && (
						<Button className={styles.button1} href={buttonOneLink}>
							{buttonOneText}
						</Button>
					)}

					{buttonTwoLink && (
						<LinkWithArrow
							className={styles.button2}
							href={buttonTwoLink}
							arrowClassName={styles.button2Arrow}
						>
							{buttonTwoText}
						</LinkWithArrow>
					)}
				</div>
			</div>
		</section>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyRideOptionDetailsFragment>) {
	return {
		image: data.primary.image?.gatsbyImageData as IGatsbyImageData,
		imageAlt: data.primary.image?.alt,
		heading: data.primary.heading?.text,
		buttonOneLink: data.primary.button_1_link?.url,
		buttonOneText: data.primary.button_1_text,
		buttonTwoLink: data.primary.button_2_link?.url,
		buttonTwoText: data.primary.button_2_text,
		stats: data.items.map((item) => ({
			heading: item.statistic_heading?.text,
			subheading: item.statistic_subheading?.richText,
		})),
	}
}

export const fragment = graphql`
	fragment PageDataBodyRideOptionDetails on PrismicPageDataBodyRideOptionDetails {
		primary {
			image {
				gatsbyImageData(
					width: 1000
					layout: FULL_WIDTH
					sizes: "(min-width: 64rem) 50vw, 100vw"
				)
				alt
			}
			heading {
				text
			}
			button_1_link {
				url
			}
			button_1_text

			button_2_link {
				url
			}
			button_2_text
		}

		items {
			statistic_heading {
				text
			}
			statistic_subheading {
				richText
			}
		}
	}
`

export default PageDataBodyRideOptionDetails
