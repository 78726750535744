import clsx from "clsx"

import * as styles from "./ContentContainer.module.css"

export interface ContentContainerProps
	extends React.ComponentPropsWithoutRef<"div"> {
	centered?: boolean
	variant?: "base" | "narrow" | "wide" | "full"
}

export const ContentContainer = ({
	centered = true,
	variant = "base",
	className,
	...props
}: ContentContainerProps) => {
	return (
		<div
			className={clsx(className, styles.container)}
			data-centered={centered}
			data-variant={variant}
			{...props}
		/>
	)
}
