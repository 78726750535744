import { graphql } from "gatsby"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"

import type { PageDataBodyHomeHeroFragment } from "../graphql.gen"

import * as styles from "./PageDataBodyHomeHero.module.css"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyHomeHero = ({
	video,
	coverImage,
	coverImageAlt,
	heading,
	subheading,
	background,
	backgroundAlt,
}: Props) => {
	return (
		<section className={styles.homeHero}>
			<div className={styles.homeHeroMedia}>
				{coverImage && (
					<GatsbyImage
						image={coverImage}
						alt={coverImageAlt || ""}
						objectFit="cover"
						objectPosition="center"
					/>
				)}

				{video && (
					<video autoPlay muted loop playsInline>
						<source src={video} type="video/mp4" />
					</video>
				)}
			</div>

			<div className={styles.homeHeroHeadings}>
				{heading && <h1>{heading}</h1>}
				{subheading && <h2>{subheading}</h2>}

				{background && (
					<img src={background} role="presentation" alt={backgroundAlt || ""} />
				)}
			</div>
		</section>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyHomeHeroFragment>) {
	return {
		video: data.primary.video?.url,
		coverImage: data.primary.cover_image?.gatsbyImageData as IGatsbyImageData,
		coverImageAlt: data.primary.cover_image?.alt,
		heading: data.primary.heading?.text,
		subheading: data.primary.subheading?.text,
		background: data.primary.background_illustration?.url,
		backgroundAlt: data.primary.background_illustration?.alt,
	}
}

export const fragment = graphql`
	fragment PageDataBodyHomeHero on PrismicPageDataBodyHomeHero {
		primary {
			video {
				url
			}
			cover_image {
				gatsbyImageData(width: 1600, layout: FULL_WIDTH)
				alt
			}
			heading {
				text
			}
			subheading {
				text
			}
			background_illustration {
				url
				alt
			}
		}
	}
`

export default PageDataBodyHomeHero
