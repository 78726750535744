import * as React from "react"
import { PrismicLink } from "@prismicio/react"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import { useLocation } from "@gatsbyjs/reach-router"
import { isFilled } from "@prismicio/helpers"
import { RichText } from "../components/RichText"
import { Button } from "../components/Button"
import clsx from 'clsx'

import type { StoriesPageQuery } from "../graphql.gen"

import * as typography from "../styles/typography.module.css"
import * as cardStyles from '../slices/PageDataBodyStoryCards.module.css'
import * as styles from './PageDataBodyAllStories.module.css'

interface ExtraCardProps {
  reference?: HTMLDivElement
}

const StoryCard = ({
  image,
  imageAlt,
  title,
  summary,
  href,
  reference
}: Props["posts"][number] & ExtraCardProps) => {
  return (
    <li ref={reference}>
      <PrismicLink
        href={href}
        className={clsx(
          cardStyles.cardContainer,
          styles.storyLink
        )}
      >
        <div className={cardStyles.imageContainer}>
          <GatsbyImage
            image={image}
            className={cardStyles.inner}
            alt={imageAlt ?? ""}
          />
        </div>

        <div className={cardStyles.textContainer}>
          {title && (
            <h3 className={cardStyles.cardHeading}>
              {title}
            </h3>
          )}

          {isFilled.richText(summary) && (
            <RichText className={cardStyles.cardSubheading}
              field={summary}
              componentOverrides={{
                paragraph: (props) => (
                  <p className={cardStyles.cardSubheading}>
                    {props.children}
                  </p>
                )
              }}
            />
          )}
        </div>
      </PrismicLink>
    </li>
  )
}

type Props = ReturnType<typeof mapDataToProps>

interface LocationState {
  hasUserNavigated?: boolean
}

export const PageDataBodyAllStories = ({
  posts,
  indexProps,
  totalPages,
  currentPage,
}: Props) => {
  const STORIES_PER_PAGE = 9
  const containerRef = React.useRef<HTMLDivElement>(null)
  const location = useLocation()
	const locationState = location.state as LocationState | undefined

  const gridHeading = indexProps?.heading
  const gridCopy = indexProps?.copy

  React.useEffect(() => {
    if (!locationState?.hasUserNavigated) return

    containerRef.current?.scrollIntoView()
  }, [locationState?.hasUserNavigated])

  return (
    <section className={clsx(cardStyles.container, styles.container)}>
      <div className={styles.storyCopy}>
        {gridHeading && (
          <h2 className={clsx(typography.heading2, cardStyles.header)}>
            {gridHeading}
          </h2>
        )}

        {isFilled.richText(gridCopy) && (
          <RichText
            field={gridCopy}
            className={clsx(typography.paragraph1, cardStyles.subheading)}
          />
        )}
      </div>

      <div>
        <ul className={clsx(
          cardStyles.images,
          styles.storyList
        )}>
          {posts.map((post, index: number) => (
            <StoryCard
              key={index}
              reference={index === (STORIES_PER_PAGE * currentPage) - 1 ? containerRef : null}
              {...post}
            />
          ))}
        </ul>
      </div>

      <div className={styles.loadMore}>
        <Button
          href={'/stories/' + (currentPage + 1)}
          className={clsx(
            styles.loadMoreButton,
            currentPage === totalPages && styles.hidden
          )}
          state={{ hasUserNavigated: true }}
        >
          Load More
        </Button>
      </div>
    </section>
  )
}

interface IndexProps {
  heading?: string
  copy?: object
}

export interface AllStoriesData {
	__typename: "PrismicPageDataBodyAllStories"
	posts: StoriesPageQuery["allPrismicStory"]["nodes"]
  indexProps: IndexProps
	totalPages: number
	currentPage: number
}

export function mapDataToProps({ data }: MapDataToPropsCtx<AllStoriesData>) {
	const posts = data.posts.map((node) => {
		return {
      image: node.data.image?.gatsbyImageData as IGatsbyImageData,
			imageAlt: node.data.image?.alt,
			title: node.data.title?.text,
			summary: node.data.summary?.richText,
			href: node.url,
      publishedAt: node.data.publish_date
		}
	})

	return {
		posts,
    indexProps: data.indexProps,
		currentPage: data.currentPage,
		totalPages: data.totalPages,
	}
}

export default PageDataBodyAllStories