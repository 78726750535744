import clsx from "clsx"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import { useLocation } from "@gatsbyjs/reach-router"

import type { PageDataBodyCallToActionFragment } from "../graphql.gen"

import wheelDriveUrl from "../assets/gears-drive.svg"
import wheelOutlineUrl from "../assets/gears-outline-purple.svg"
import { useRotateOnScroll } from "../hooks/useRotateOnScroll"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { Button } from "../components/Button"

import * as typography from "../styles/typography.module.css"
import * as styles from "./PageDataBodyCallToAction.module.css"

type Props = ReturnType<typeof mapDataToProps>

type WheelProps = {
	src: string
	className?: string
}

const Wheel = ({ src, className }: WheelProps) => {
	const rotate = useRotateOnScroll(5)

	return (
		<motion.img
			src={src}
			className={className}
			loading="lazy"
			decoding="async"
			style={{ rotate }}
		/>
	)
}

export const PageDataBodyCallToAction = ({
	heading,
	subheading,
	link,
	linkText,
}: Props) => {
	const location = useLocation()
	const isOnHomePage = location.pathname === "/"

	return (
		<section className={clsx(styles.cta, isOnHomePage && styles.ctaHome)} data-background="blue">
			<div className={clsx(isOnHomePage && styles.ctaHomeContainer)}>
				<div className={styles.wheelContainer}>
					<Wheel src={wheelDriveUrl} className={styles.gear1} />
				</div>

				<Wheel src={wheelOutlineUrl} className={styles.gear2} />
				<Wheel src={wheelOutlineUrl} className={styles.gear3} />

				<div className={styles.copy}>
					{heading && (
						<h2 className={clsx(typography.heading2, styles.heading)}>
							{heading}
						</h2>
					)}
					{subheading && (
						<h3 className={clsx(typography.ctaSubheading, styles.subheading)}>
							{subheading}
						</h3>
					)}

					{link && (
						<Button className={styles.button} href={link}>
							{linkText}
						</Button>
					)}
				</div>
			</div>
		</section>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyCallToActionFragment>) {
	return {
		heading: data.primary.heading?.text,
		subheading: data.primary.subheading?.text,
		link: data.primary.button_link?.url,
		linkText: data.primary.button_link_text,
	}
}

export const fragment = graphql`
	fragment PageDataBodyCallToAction on PrismicPageDataBodyCallToAction {
		primary {
			heading {
				text
			}
			subheading {
				text
			}
			button_link {
				url
			}
			button_link_text
		}
	}

	fragment NewsPostDataBodyCallToAction on PrismicNewsPostDataBodyCallToAction {
		primary {
			heading {
				text
			}
			subheading {
				text
			}
			button_link {
				url
			}
			button_link_text
		}
	}

	fragment StoryDataBodyCallToAction on PrismicStoryDataBodyCallToAction {
		primary {
			heading {
				text
			}
			subheading {
				text
			}
			button_link {
				url
			}
			button_link_text
		}
	}
`

export default PageDataBodyCallToAction
