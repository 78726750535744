import { graphql } from "gatsby"
import clsx from "clsx"

import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { SliceContainer } from "../components/SliceContainer"

import type { PageDataBodyDownloadAssetsFragment } from "../graphql.gen"

import * as typography from "../styles/typography.module.css"
import * as styles from "./PageDataBodyDownloadAssets.module.css"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import { Link } from "../components/Link"
import { RichText } from "../components/RichText"
import { isFilled } from "@prismicio/helpers"
import { Arrow } from "../components/icons/Arrow"

type Props = ReturnType<typeof mapDataToProps>

type Variant = "small thumbnail" | "large thumbnail" | "list"

export const PageDataBodyDownloadAssets = ({
	heading,
	variant,
	assets,
}: Props) => {
	return (
		<SliceContainer backgroundColor="red">
			{heading && (
				<h2 className={clsx(typography.heading3, styles.heading)}>{heading}</h2>
			)}
			{assets && (
				<div
					className={
						variant === "small thumbnail"
							? clsx(styles.grid, styles.smallThumbnailGrid)
							: clsx(styles.grid, styles.assetGrid)
					}
				>
					{assets.map((asset, index) => (
						<DownloadAssets
							key={index}
							type={variant}
							title={asset.assetHeading}
							description={asset.assetDescription}
							link={asset.link}
							linkText={asset.linkText}
							img={asset.image}
						/>
					))}
				</div>
			)}
		</SliceContainer>
	)
}

const DownloadAssets = ({ type, img, title, description, link, linkText }) => {
	switch (type) {
		case "small thumbnail": {
			return (
				<SmallThumbnail
					title={title}
					img={img}
					description={description}
					link={link}
					linkText={linkText}
				/>
			)
		}
		case "large thumbnail": {
			return (
				<LargeThumbnail
					title={title}
					img={img}
					description={description}
					link={link}
					linkText={linkText}
				/>
			)
		}
		default: {
			return (
				<ListView
					title={title}
					description={description}
					link={link}
					linkText={linkText}
				/>
			)
		}
	}
}

const ListView = ({ title, description, link, linkText }) => {
	return (
		<div className={clsx(styles.container, styles.listViewContainer)}>
			{title && (
				<h3 className={clsx(typography.heading5, styles.header)}>{title}</h3>
			)}

			{isFilled.richText(description) && (
				<RichText
					field={description}
					className={styles.richText}
					componentOverrides={{
						paragraph: (props) => (
							<p className={clsx(typography.paragraph2, styles.paragraph)}>
								{props.children}
							</p>
						),
					}}
				/>
			)}

			{(link || linkText) && (
				<Link
					className={styles.link}
					href={link}
					aria-label={`${linkText} for ${title}`}
				>
					{linkText} <Arrow className={styles.arrow} />
				</Link>
			)}
		</div>
	)
}

const LargeThumbnail = ({ title, img, description, link, linkText }) => {
	return (
		<div className={clsx(styles.container, styles.largeThumbnailContainer)}>
			{img && (
				<GatsbyImage
					image={img}
					alt=""
					objectFit="cover"
					objectPosition="center"
				/>
			)}

			{title && (
				<h3
					className={clsx(
						typography.heading5,
						styles.header,
						styles.largeThumbnailHeader,
					)}
				>
					{title}
				</h3>
			)}

			{isFilled.richText(description) && (
				<RichText
					field={description}
					className={styles.richText}
					componentOverrides={{
						paragraph: (props) => (
							<p className={clsx(typography.paragraph2, styles.paragraph)}>
								{props.children}
							</p>
						),
					}}
				/>
			)}

			{(link || linkText) && (
				<Link
					className={styles.largeThumbnailLink}
					href={link}
					aria-label={`${linkText} for ${title}`}
				>
					{linkText} <Arrow className={styles.arrow} />
				</Link>
			)}
		</div>
	)
}
const SmallThumbnail = ({ title, img, description, link, linkText }) => {
	return (
		<div className={styles.smallThumbnailContainer}>
			{img && (
				<GatsbyImage
					image={img}
					alt=""
					objectFit="cover"
					objectPosition="center"
					className={styles.simage}
				/>
			)}

			<div className={clsx(styles.smallThumbnailContent)}>
				{title && (
					<h3 className={clsx(typography.heading5, styles.header)}>{title}</h3>
				)}

				{isFilled.richText(description) && (
					<RichText
						field={description}
						className={styles.richText}
						componentOverrides={{
							paragraph: (props) => (
								<p className={clsx(typography.paragraph2, styles.paragraph)}>
									{props.children}
								</p>
							),
						}}
					/>
				)}

				{(link || linkText) && (
					<Link
						className={styles.link}
						href={link}
						aria-label={`${linkText} for ${title}`}
					>
						{linkText} <Arrow className={styles.arrow} />
					</Link>
				)}
			</div>
		</div>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyDownloadAssetsFragment>) {
	return {
		heading: data.primary.heading.text,
		variant: data.primary.view_type.toLowerCase() as Variant,
		assets: data.items.map((item) => {
			return {
				assetHeading: item.asset_heading?.text,
				assetDescription: item.asset_description?.richText,
				link: item.asset_link?.url,
				linkText: item.asset_link_text,
				image: item.image?.gatsbyImageData as IGatsbyImageData,
			}
		}),
	}
}

export const fragment = graphql`
	fragment PageDataBodyDownloadAssets on PrismicPageDataBodyDownloadAssets {
		primary {
			heading {
				text
			}
			view_type
		}

		items {
			asset_heading {
				text
			}
			asset_description {
				richText
			}
			asset_link {
				url
			}
			asset_link_text
			image {
				gatsbyImageData(
					width: 700
					layout: FULL_WIDTH
					breakpoints: [234, 500, 700]
				)
			}
		}
	}
`

export default PageDataBodyDownloadAssets
