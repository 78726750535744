import { Link } from "./Link"

import * as styles from './PostTitle.module.css'

interface PostTitleProps {
	title?: string
  parent?: string
  parentUrl?: string
}

export const PostTitle = ({
  title,
  parent,
  parentUrl
}: PostTitleProps) => {
  return (
    <div className={styles.postTitle}>
      {parentUrl && parent && (
        <Link href={parentUrl}>{parent}</Link>
      )}
      <h1>
        {parentUrl && !parent ? (
          <Link href={parentUrl}>{title}</Link>
        ) : (
          <>{title}</>
        )}
      </h1>
    </div>
  )
}