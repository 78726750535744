import clsx from "clsx"
import { graphql } from "gatsby"
import { isFilled } from "@prismicio/helpers"
import type { IGatsbyImageData } from "gatsby-plugin-image"

import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { RichText } from "../components/RichText"
import { Button } from "../components/Button"
import { SliceContainer } from "../components/SliceContainer"
import { GearGatsbyImage } from "../components/GearGatsbyImage"

import type { PageDataBodyGearPromoFragment } from "../graphql.gen"
import * as typography from "../styles/typography.module.css"
import * as styles from "./PageDataBodyGearPromo.module.css"

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyGearPromo = ({
	gearOneImage,
	gearOneAlt,
	gearTwoImage,
	gearTwoAlt,
	gearThreeImage,
	gearThreeAlt,
	heading,
	copyText,
	buttonOneText,
	buttonOneLink,
	buttonTwoText,
	buttonTwoLink,
	bulletItems,
}: Props) => {
	return (
		<SliceContainer
			backgroundColor="blue"
			className={styles.container}
			data-background="blue"
		>
			<div className={styles.subContainer}>
				<div className={styles.images}>
					{gearOneImage && (
						<GearGatsbyImage
							className={styles.gearImageWrapper}
							image={gearOneImage}
							alt={gearOneAlt ?? ""}
						/>
					)}

					{gearTwoImage && (
						<GearGatsbyImage
							className={styles.gearImageWrapper}
							image={gearTwoImage}
							alt={gearTwoAlt ?? ""}
						/>
					)}

					{gearThreeImage && (
						<GearGatsbyImage
							className={styles.gearImageWrapper}
							image={gearThreeImage}
							alt={gearThreeAlt ?? ""}
						/>
					)}
				</div>

				<div className={styles.content}>
					<div className={styles.text}>
						{heading && <h2 className={typography.heading2}>{heading}</h2>}

						{isFilled.richText(copyText) && (
							<RichText
								field={copyText}
								className={clsx(styles.copy, typography.paragraph1)}
							/>
						)}
					</div>

					<ul className={styles.list}>
						{bulletItems.map((item, index) => (
							<li key={index} className={typography.listItem}>
								{item.bullet}
							</li>
						))}
					</ul>

					<div className={styles.buttonContainer}>
						<Button href={buttonOneLink} className={styles.buttons}>
							{buttonOneText}
						</Button>

						<Button
							href={buttonTwoLink}
							className={clsx(styles.buttons, styles.buttonTwo)}
						>
							{buttonTwoText}
						</Button>
					</div>
				</div>
			</div>
		</SliceContainer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PageDataBodyGearPromoFragment>) {
	return {
		gearOneImage: data.primary.gear_one_image
			?.gatsbyImageData as IGatsbyImageData,
		gearOneAlt: data.primary.gear_one_image?.alt,
		gearTwoImage: data.primary.gear_two_image
			?.gatsbyImageData as IGatsbyImageData,
		gearTwoAlt: data.primary.gear_one_image?.alt,
		gearThreeImage: data.primary.gear_three_image
			?.gatsbyImageData as IGatsbyImageData,
		gearThreeAlt: data.primary.gear_one_image?.alt,
		heading: data.primary.heading?.text,
		copyText: data.primary.copy_text?.richText,
		buttonOneText: data.primary.button_one_text,
		buttonOneLink: data.primary.button_one_link?.url,
		buttonTwoText: data.primary.button_two_text,
		buttonTwoLink: data.primary.button_two_link?.url,
		bulletItems: data.items.map((item) => {
			return {
				bullet: item.bullet_text,
			}
		}),
	}
}

export const fragment = graphql`
	fragment PageDataBodyGearPromo on PrismicPageDataBodyGearPromo {
		primary {
			gear_one_image {
				alt
				gatsbyImageData(
					width: 500
					sizes: "(min-width: 90rem) 26rem, (min-width: 64rem) 20rem, (min-width: 48rem) 26vw, 62.5vw"
				)
			}
			gear_two_image {
				alt
				gatsbyImageData(
					width: 500
					sizes: "(min-width: 90rem) 16.1875rem, (min-width: 64rem) 12.4rem, (min-width: 48rem) 25vw, 60vw"
				)
			}
			gear_three_image {
				alt
				gatsbyImageData(
					width: 500
					sizes: "(min-width: 90rem) 20.5625rem, (min-width: 64rem) 16rem, (min-width: 48rem) 20.8vw, 50vw"
				)
			}
			heading {
				text
			}
			copy_text {
				richText
			}
			button_one_text
			button_one_link {
				url
			}
			button_two_text
			button_two_link {
				url
			}
		}

		items {
			bullet_text
		}
	}
`

export default PageDataBodyGearPromo
