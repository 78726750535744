/**
 * This file contains a template for all News Post documents in your Prismic
 * repository. It uses Gatsby's File System Route API to automatically generate
 * a page for each document using its `url` field. The `url` field is computed
 * using your app's Link Resolver.
 *
 * This template supports Prismic previews using the `withPrismicPreview` higher
 * order component.
 *
 * @see https://www.gatsbyjs.com/docs/reference/routing/file-system-route-api/
 */

import { graphql, HeadProps, type PageProps } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { GatsbyImage, type IGatsbyImageData } from "gatsby-plugin-image"
import { MapSlicesToComponents } from "../components/MapSlicesToComponents"
import { SEO } from "../components/SEO"
import { PostTitle } from "../components/PostTitle"
import { Header } from "../components/Header"
import { useSiteSettings } from "../hooks/useSiteSettings"
import { dateFormatter } from "../slices/PageDataBodyAllNewsPosts"
import { NewsPostTemplateQuery } from "../graphql.gen"
import * as styles from "../components/PostIntro.module.css"

interface PostIntroProps {
	title?: string
	tags?: Array<string>
	published?: string
	image?: IGatsbyImageData
	imageAlt?: string
}

const PostIntro = ({
	title,
	tags,
	published,
	image,
	imageAlt,
}: PostIntroProps) => {
	const publishDateParts = published
		? dateFormatter
				.formatToParts(new Date(published))
				.filter((part) => part.type !== "literal")
		: undefined

	return (
		<section className={styles.postIntro}>
			<div className={styles.postDetails}>
				{tags && <p className={styles.tags}>{tags.join(", ")}</p>}

				<h2 className={styles.title}>{title}</h2>

				{publishDateParts && (
					<p className={styles.published}>
						{publishDateParts[0].value}.{publishDateParts[1].value}.
						{publishDateParts[2].value}
					</p>
				)}
			</div>

			<div className={styles.postImage}>
				{image && (
					<GatsbyImage
						image={image}
						objectFit="cover"
						objectPosition="center"
						alt={imageAlt || ""}
					/>
				)}
			</div>
		</section>
	)
}

const NewsPostTemplate = ({ data }: PageProps<NewsPostTemplateQuery>) => {
	const post = data.prismicNewsPost
	const tags = post?.tags
	const title = post?.data.title?.text
	const published = post?.data?.publish_date
	const image = post?.data?.image?.gatsbyImageData as IGatsbyImageData
	const imageAlt = post?.data?.image?.alt

	return (
		<>
			<Header />
			<PostTitle title="News & Media" parentUrl="/news" />
			<PostIntro
				title={title}
				tags={tags}
				published={published}
				image={image}
				imageAlt={imageAlt}
			/>
			<MapSlicesToComponents slices={post?.data.body} />
		</>
	)
}

export const Head = ({ data }: HeadProps<NewsPostTemplateQuery>) => {
	const settings = useSiteSettings()
	const post = data.prismicNewsPost

	return (
		<SEO
			siteName={settings.siteName}
			siteDescription={settings.siteDescription}
			pageTitle={post?.data.title?.text}
			meta={{
				description: post?.data.meta_description?.text,
				title: post?.data.meta_title?.text,
			}}
			twitter={{
				username: settings.twitter.username,
				cardImageUrl:
					post?.data.twitter_card?.url ?? settings.twitter.cardImageUrl,
			}}
			openGraph={{
				cardImageUrl:
					post?.data.open_graph_image?.url ?? settings.openGraph.cardImageUrl,
			}}
		/>
	)
}

/**
 * When a Prismic preview session is active, `withPrismicPreview` will
 * automatically provide your template with updated preview content. As editors
 * edit and save content in the Prismic writing room, the page will
 * automatically refresh to display the edited content.
 *
 * @see https://prismic.io/docs/technical-reference/gatsby-plugin-prismic-previews#withprismicpreview
 */
export default withPrismicPreview(NewsPostTemplate)

export const query = graphql`
	query NewsPostTemplate($id: String!) {
		prismicNewsPost(id: { eq: $id }) {
			_previewable
			tags
			data {
				meta_description {
					text
				}
				meta_title {
					text
				}
				twitter_card {
					url(imgixParams: { width: 800, height: 418, q: 75 })
				}
				open_graph_image {
					url(imgixParams: { width: 1200, height: 630, q: 75 })
				}

				title {
					text
				}
				image {
					alt
					gatsbyImageData(
						width: 900
						breakpoints: [400, 600]
						layout: FULL_WIDTH
					)
				}
				publish_date

				body {
					__typename
					...NewsPostDataBody
				}
			}
		}
	}
`
