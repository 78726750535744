// extracted by mini-css-extract-plugin
export var ctaSubheading = "typography-module--ctaSubheading--UM6zc";
export var heading2 = "typography-module--heading2--+uC3r";
export var heading3 = "typography-module--heading3--D8J55";
export var heading4 = "typography-module--heading4--nTb94";
export var heading5 = "typography-module--heading5--e55ET";
export var list = "typography-module--list--MgG-D";
export var listItem = "typography-module--listItem---+v88";
export var orderedList = "typography-module--orderedList--pg+5+";
export var orderedListItem = "typography-module--orderedListItem--vzY0z";
export var paragraph1 = "typography-module--paragraph1--W4DMk";
export var paragraph2 = "typography-module--paragraph2--OWUWz";
export var quote = "typography-module--quote--kyg02";
export var quotee = "typography-module--quotee--Vll5P";