import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import { isInternal } from "@walltowall/helpers"

interface InternalLinkProps {
  state?: object
}

export type LinkProps = React.ComponentPropsWithoutRef<"a"> & InternalLinkProps

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
	({ href, state, ...props }, ref) => {
		if (!href) {
			return <a ref={ref} {...props} />
		}

		if (!isInternal(href)) {
			return (
				<a
					ref={ref}
					href={href}
					target="_blank"
					rel="noopener noreferrer nofollow"
					{...props}
				/>
			)
		}

		//@ts-expect-error - Forward Ref types. It's an anchor tag at the end of the
		//day, so safe to ignore.
		return <GatsbyLink ref={ref} to={href} state={state} {...props} />
	},
)

Link.displayName = "Link"
