// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--r9kKa";
export var footerContainer = "Footer-module--footerContainer--Y00s9";
export var footerCopyright = "Footer-module--footerCopyright--ege+d";
export var footerCredits = "Footer-module--footerCredits--7MQbV";
export var footerLogo = "Footer-module--footerLogo--rgzaq";
export var footerNavigation = "Footer-module--footerNavigation--d3Wzv";
export var footerSocial = "Footer-module--footerSocial--qv8mr";
export var footerTop = "Footer-module--footerTop--occO1";
export var logoContainer = "Footer-module--logoContainer--QqQCe";
export var message = "Footer-module--message--bmmZe";